import React, { useEffect, useState, useCallback, useRef, useContext } from "react"
import { navigate } from "gatsby"
import _ from "lodash"
import Loadable from "@loadable/component"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import Modal from "react-bootstrap/Modal"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Dropdown from "react-bootstrap/Dropdown"
import Spinner from "react-bootstrap/Spinner"
import Card from "react-bootstrap/Card"
import InputGroup from "react-bootstrap/InputGroup"
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"

import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Sidebar from "../../components/Sidebar/template-sidebar"
import UploadFiles from "../courses/upload-files2"
import Datetime from "react-datetime"
import utcDateTime from "../../services/utcdatetime"
import Common from "../../services/common"
import TestBreakoutSession from "../template-resources/breakout-session"
import BreakoutSessionSection from "../courses/breakout-session-section"
import Blog from "../blogs/create-edit"
import DeleteModal from "../custom/DeleteModal"
import { isBrowser } from "../../services/developer_mode"
import Loader from "../custom/Loader"
import { getRequest, deleteRequest, postRequest } from "../../services/rest_service"
import LayoutHeader from "../layoutHeader"
import Image from "../custom/Image"
import QuestionStore from "../../stores/question_store/QuestionStore"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLink,
  faVideo,
  faUserCircle,
  faHeart,
  faClock,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronRight,
  faCheck,
  faChevronLeft,
  faSpinner,
  faMugHot,
  faEllipsisV,
  faImage,
  faUsers,
  faAngleDown,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons"

import DragHandleIcon from "../../images/svgs/drag-handle-gray.svg"
import AddDescriptionIcon from "../../images/svgs/adddescriptionicon.svg"
import AddedDescriptionIcon from "../../images/svgs/addeddescriptionicon.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quiz from "../../images/svgs/quiz.svg"
import CancelWhite from "../../images/svgs/close.svg"
import WorkshopInfo from "../../images/svgs/live_workshop_info.svg"
import documents from "../../images/svgs/documentresWhite.svg"
import WhiteBlogIcon from "../../images/svgs/blog-white-icon.svg"
import WhiteQuizIcon from "../../images/svgs/quiz-white-icon.svg"
import whiteMatchingIcon from "../../images/svgs/whiteMatchingIcon.svg"
import WhitePollIcon from "../../images/svgs/polls-white-icon.svg"
import CancelIcon from "../../images/svgs/cancel-white-icon.svg"
import doc from "../../images/svgs/doc.svg"
import excel from "../../images/svgs/excel.svg"
import ppt from "../../images/svgs/ppt.svg"
import pdf from "../../images/svgs/pdf.svg"
import poll from "../../images/svgs/poll.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"
import BreakoutGroupsCreation from "../../images/svgs/breakout_groups_creation.svg"

import Styles from "../../modularscss/styles.module.scss"
import StylesA from "../template-resources/breakout-session.module.scss"
import StylesB from "../courses/course.module.scss"
import StylesM from "../../components/upload-resource.module.scss"
import StylesQ from "../quizzes/quiz.module.scss"
import { WsConnect } from "../../services/socket"
import ActivityRoomWokSpace from "../mural/mural-room-workspace"
import CloseBtn from "../close-btn"
import { CLASSES } from "../../constants/permissionsConstant"
import { HasEditPermission, HasReadPermission } from "../../utils/PermissionDataAccess"
import NoPermissionError from "../no-permission-error"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"
import AutoSaveLoader from "../autosave-loader"

const WorkSpaceRoom = Loadable(() => import("../mural/mural-room-workspace/workspaceRoom"))

const getTopicItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#42506C",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})
const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  // background: '#42506C',
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  background: "#212C42",
  padding: "12px",
  borderRadius: "0px 0px 8px 8px",
  maxHeight: "calc(100vh - 230px)",
  overflowY: "auto",
})
const getTopicListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  // background: '#212C42',
  // padding: grid,
})

export const NewCheckBox = (props) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        props.changeInParent(props.change_value)
      }}
      className={`d-flex mb-2 br-9px p-3 inner-element-box-shadow pointer border_on_hover text-color-1 ${props.checked ? StylesB.bg_4 : StylesB.bg_3}`}
      style={{ border: "1px solid transparent" }}
    >
      <div
        className="text-center d-flex align-items-center justify-content-center mr-2"
        style={{
          backgroundColor: "#303C54",
          borderRadius: "50%",
          minWidth: "35px",
          border: "1px solid white",
          height: "35px",
        }}
      >
        {props.checked ? <FontAwesomeIcon style={{ verticalAlign: "bottom", fontSize: "13px" }} icon={faCheck} /> : ""}
      </div>
      <div className={`ml-2 align-self-center fs-14px ${!props.checked ? "opacity-69" : ""}`}>{props.children}</div>
    </div>
  )
}
const WeightageField = (props) => {
  const [inputValue, setInputValue] = useState(props.datum.weightage)
  const isPermitted = !HasEditPermission(CLASSES)
  function sendQuery(name, value, datum, type) {
    props.handleSave(name, value, datum, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  return (
    <Dropdown className={"session-time-drop-down col-3 px-0 ml-2"}>
      <Dropdown.Toggle as="div" id="dropdown-custom-components" className={`${isPermitted && "disable-content"}`}>
        <div
          className="px-0"
          style={{
            position: "relative",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "8px",
            background: "#42506C",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "text",
            }}
            className={`${StylesB.session_input_field} ${StylesB.session_number_field} border_on_hover px-2 ${Styles.font_16}`}
          >
            Weightage: {inputValue}
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${StylesB.active_slider_drop_down}`}>
        <input
          autoComplete="off"
          type="range"
          min="0"
          max="100"
          name="weightage"
          autoFocus
          id={props.datum.id + `_topic_weightage`}
          value={inputValue}
          onChange={(e) => {
            e.preventDefault()
            setInputValue(e.target.value)
            delayedQuery("weightage", e.target.value, props.datum, "topic")
          }}
          placeholder={"Weightage"}
          className={`${StylesB.topic_weightage_slider} ${Styles.font_16}`}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}
const DurationInputField = (props) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [timeType, setTimeType] = useState("Mins")
  const [inputValue, setInputValue] = useState(props.datum.duration)
  const isPermitted = !HasEditPermission(CLASSES)

  useEffect(() => {
    if (inputValue !== props.datum.duration) {
      let current_value = timeType === "Mins" ? inputValue : inputValue * 60
      props.handleSave("duration", current_value, props.datum, "topic")
    }
  }, [inputValue])

  return (
    <Dropdown className={"session-time-drop-down col-3 px-0"}>
      <Dropdown.Toggle as="div" id="dropdown-custom-components" className={` ${isPermitted && "disable-content"}`}>
        <div
          className="px-0"
          style={{
            position: "relative",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "8px",
            background: "#42506C",
          }}
        >
          {showDropDown ? (
            <input
              type="number"
              min="1"
              name="duration"
              autoFocus
              autoComplete="off"
              id={props.datum.id + `_module_duration`}
              value={inputValue}
              onChange={(e) => {
                e.preventDefault()
                setInputValue(e.target.value)
              }}
              placeholder={"Session duration"}
              className={`${StylesB.session_input_field} ${StylesB.session_number_field} form-control border-0 px-3 ${Styles.font_16}`}
              onClick={(e) => {
                e.stopPropagation()
                setShowDropDown(!showDropDown)
              }}
              onBlur={() => setShowDropDown(false)}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "text",
              }}
              onClick={() => {
                setShowDropDown(!showDropDown)
              }}
              className={`${StylesB.session_input_field} ${StylesB.session_number_field} border_on_hover px-2 ${Styles.font_16}`}
            >
              Duration: {inputValue} {timeType}
            </div>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu show={showDropDown} className={`${StylesB.active_input_drop_down}`}>
        {timeType === "Mins" ? (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(10)
              }}
            >
              <p>10 mins</p>
            </div>
            <div
              onClick={() => {
                setInputValue(20)
              }}
            >
              <p>20 mins</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(1)
              }}
            >
              <p>1 hrs</p>
            </div>
            <div
              onClick={() => {
                setInputValue(2)
              }}
            >
              <p>2 hrs</p>
            </div>
          </React.Fragment>
        )}
        <div
          style={{
            fontSize: "12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <p onClick={() => setTimeType("Mins")} style={{ opacity: timeType === "Mins" ? 1 : 0.5 }}>
            Mins
          </p>
          <p
            onClick={() => setTimeType("Hours")}
            style={{
              marginLeft: "10px",
              opacity: timeType === "Hours" ? 1 : 0.5,
            }}
          >
            Hours
          </p>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
const SessionTimingField = (props) => {
  const [startDate, setStartDate] = useState(props.datum.start_date ? new Date(props.datum.start_date) : "")
  const [endDate, setEndDate] = useState(props.datum.end_date ? new Date(props.datum.end_date) : "")
  const isPermitted = !HasEditPermission(CLASSES)

  function sendQuery(name, value, datum, type) {
    props.handleSave(name, value, datum, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )
  const delayedQuery1 = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )
  let start_date = startDate ? utcDateTime.utcDateTime(startDate, "time") : "00:00"
  let end_date = endDate ? utcDateTime.utcDateTime(endDate, "time") : "00:00"
  return (
    <Dropdown className={"session-time-drop-down"}>
      <Dropdown.Toggle as="div" id="dropdown-custom-components" className={`${isPermitted && "disable-content"}`}>
        <div
          className={`px-0 ${props.datum.module_type === "5" ? `ml-auto font-weight-bold` : ``}`}
          style={{
            position: "relative",
            minWidth: "300px",
            borderLeft: props.datum.module_type === "5" ? `1px solid #ffffff40` : `0px solid #ffffff40`,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "text",
            }}
            className={`${StylesB.session_input_field} ${StylesB.session_number_field} ${props.datum.module_type !== "5" ? "border_on_hover" : ""} px-2 ${
              Styles.font_16
            }`}
          >
            {props.datum.module_type === "5" ? "" : "Session "}Timings: {start_date} - {end_date}
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${StylesB.active_calendar_drop_down}`}>
        <Col lg="12" className="d-flex" style={{ padding: "12px" }}>
          <Col lg="6" className="pl-0" style={{ paddingRight: "6px" }}>
            <p
              style={{
                fontSize: "12px",
                color: "#E0E2E5",
                marginBottom: "0px",
              }}
            >
              From
            </p>
            <Datetime
              input={false}
              dateFormat={false}
              timeFormat="hh:mm A"
              closeOnSelect={true}
              onChange={(e) => {
                setStartDate(e)
                delayedQuery("start_date", e, props.datum, "module")
              }}
              value={startDate}
              inputProps={{
                placeholder: "Start time",
                className: "video-modal p-0",
              }}
              style={{
                background: "inherit",
                color: "inherit",
                height: "100%",
              }}
              className={`session_start_time_picker blue-bg video-modal`}
            />
          </Col>
          <Col lg="6" className="pr-0" style={{ paddingLeft: "6px" }}>
            <p
              style={{
                fontSize: "12px",
                color: "#E0E2E5",
                marginBottom: "0px",
              }}
            >
              To
            </p>
            <Datetime
              input={false}
              dateFormat={false}
              timeFormat="hh:mm A"
              closeOnSelect={true}
              onChange={(e) => {
                setEndDate(e)
                delayedQuery1("end_date", e, props.datum, "module")
              }}
              value={endDate}
              inputProps={{
                placeholder: "End time",
                className: "video-modal p-0",
              }}
              style={{
                background: "inherit",
                color: "inherit",
                height: "100%",
              }}
              className={`session_start_time_picker blue-bg video-modal`}
            />
          </Col>
        </Col>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const DescriptionInputField = (props) => {
  const [inputValue, setInputValue] = useState(props.datum.description)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (props.moduleEdited) {
      setLoader(false)
    }
  }, [props.moduleEdited])

  function sendQuery(name, value, datum, type) {
    props.handleSave(name, value, datum, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  function focusOnData(e, id) {
    let data = document.getElementById(id)
    if (data) {
      data.focus()
    }
  }

  return (
    <div>
      <div className={`w-100 mb-2 d-inline-flex align-items-center`}>
        <p className={`mb-0`} style={{ color: "#E0E2E5", fontSize: "16px", fontWeight: "500" }}>
          {props.selectedStageName === "Workshop" && props.course.course_type !== "1" ? "Add Session Objective" : "Add Module Description"}
        </p>
        <img
          onClick={() => {
            props.showModalHandler(false)
          }}
          src={CancelWhite}
          alt={`Cancel`}
          className={`ml-auto mb-0 pointer`}
          style={{ objectFit: `contain`, height: `13px` }}
        />
      </div>
      <div
        className="px-0 border_on_hover pl-0"
        style={{
          backgroundColor: "#42506C",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "9px",
          border: "1px solid #303C54",
          position: "relative",
        }}
      >
        <InputGroup className={`py-0 d-block ${!props?.isPermitted && "disable-content"}`} style={{ borderRadius: "9px", border: "1px solid transparent" }}>
          <InputGroup.Prepend
            className="border-0"
            style={{
              backgroundColor: "#42506C",
              borderRadius: "9px 9px 0px 0px",
            }}
          >
            <InputGroup.Text
              className="border-0 d-flex justify-content-center pb-0"
              style={{
                backgroundColor: "#42506C",
                borderRadius: "9px 0px 0px 9px",
                color: "#E0E2E5",
                opacity: "41%",
                fontSize: "16px",
              }}
              id="basic-addon1"
              onClick={(e) => {
                e.stopPropagation()
                focusOnData(e, "description")
              }}
            >
              {props.selectedStageName === "Workshop" && props.course.course_type !== "1" ? "Session Objective" : "Module Description"}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            className={`border-0 d-flex pt-0 w-100 justify-content-center shadow-none  text-white`}
            style={{
              backgroundColor: "#42506C",
              borderRadius: "0px",
              fontSize: "16px",
            }}
            as="textarea"
            type="text"
            name="description"
            value={inputValue}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onChange={(e) => {
              e.preventDefault()
              setInputValue(e.target.value)
              setLoader(true)
              delayedQuery(e.target.name, e.target.value, props.datum, "module")
            }}
            id="description"
            placeholder=""
          />
        </InputGroup>
      </div>
      {props?.isPermitted && (
        <>
          <AutoSaveLoader
            isLoading={loader}
            onClick={() => {
              props.showModalHandler(false)
            }}
          />
        </>
      )}
    </div>
  )
}
const NameInputField = (props) => {
  const [inputValue, setInputValue] = useState(props.datum.name)
  const [loader, setLoader] = useState(false)
  const [showInputField, setShowInputField] = useState(false)
  const isPermitted = !HasEditPermission(CLASSES)
  useEffect(() => {
    if (props.moduleEdited) {
      setLoader(false)
    }
  }, [props.moduleEdited])

  function sendQuery(name, value, datum, type) {
    props.handleSave(name, value, datum, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  function focusOnData(e, id) {
    let data = document.getElementById(id)
    if (data) {
      data.focus()
    }
  }
  function showModuleName(datum) {
    if (props.selectedStageName === "Workshop") {
      let start_date = datum.start_date ? utcDateTime.utcDateTime(datum.start_date, "time") : "00:00"
      let end_date = datum.end_date ? utcDateTime.utcDateTime(datum.end_date, "time") : "00:00"
      if (props.course.course_type === "0") {
        return `${datum.name} (${start_date} -  ${end_date}, Day ${datum.course_day})`
      } else if (props.course.course_type === "3") {
        return `${datum.name} (${start_date} -  ${end_date})`
      } else {
        return `${datum.name}`
      }
    } else {
      return `${datum.name}`
    }
  }
  function showTopicName(datum) {
    if (props.course.course_type !== "1" && props.selectedStageName === "Workshop") {
      return `${datum.name} (${datum.duration} Mins)`
    } else {
      return `${datum.name}`
    }
  }
  if (props.type === "module") {
    return (
      <div style={{ width: "100%", position: "relative", overflow: "hidden" }}>
        {showInputField || props.datum.module_type === "5" ? (
          <input
            autoComplete="off"
            type="text"
            name="name"
            id={props.datum.id + `_module_input_name`}
            onBlur={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowInputField(false)
            }}
            defaultValue={props.datum.name}
            onChange={(e) => {
              e.preventDefault()
              setInputValue(e.target.value)
              setLoader(true)
              delayedQuery(e.target.name, e.target.value, props.datum, props.type)
            }}
            autoFocus
            placeholder={"Enter Name"}
            className={`${StylesB.session_input_field} ${isPermitted && "disable-content"} form-control px-2 font-weight-bold ${
              Styles.font_16
            } d-inline-flex w-100`}
            disabled={props.disabled}
            readOnly={isPermitted}
            required
          />
        ) : (
          <p
            disabled={props.disabled}
            readOnly={isPermitted}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowInputField(true)
            }}
            className={`${StylesB.activity_input_field} ${isPermitted && "disable-content"} font-weight-bold border-0 px-2 my-auto ${Styles.font_16}`}
            style={{ minHeight: `38px`, cursor: "text", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", lineHeight: 2.4 }}
          >
            {showModuleName(props.datum)}
          </p>
        )}
        {loader ? (
          <svg
            className="animate-spin my-auto"
            style={{
              height: `16px`,
              width: `16px`,
              position: "absolute",
              top: "10px",
              right: "0px",
              zIndex: 3,
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : null}
      </div>
    )
  } else {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        {showInputField ? (
          <input
            autoComplete="off"
            type="text"
            name="name"
            id={props.datum.id + `_topic_input_name`}
            onBlur={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowInputField(false)
            }}
            defaultValue={props.datum.name}
            onChange={(e) => {
              e.preventDefault()
              setInputValue(e.target.value)
              setLoader(true)
              delayedQuery(e.target.name, e.target.value, props.datum, props.type)
            }}
            autoFocus
            placeholder={"Activity name"}
            className={`${StylesB.activity_input_field} ${isPermitted && "disable-content"} font-weight-bold form-control border-0 px-2 ${Styles.font_16}`}
            disabled={props.disabled}
            readOnly={isPermitted}
            required
          />
        ) : (
          <p
            style={{ cursor: "text", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", lineHeight: 2 }}
            disabled={props.disabled}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowInputField(true)
            }}
            readOnly={isPermitted}
            className={`${StylesB.activity_input_field} ${isPermitted && "disable-content"} align-items-center font-weight-bold border-0 px-2 my-auto ${
              Styles.font_16
            }`}
          >
            {showTopicName(props.datum)}
          </p>
        )}
        {loader ? (
          <svg
            className="animate-spin my-auto"
            style={{
              height: `16px`,
              width: `16px`,
              position: "absolute",
              top: "10px",
              right: "0px",
              zIndex: 3,
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : null}
      </div>
    )
  }
}

const CourseContent = (location, props) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""
  const isPermitted = !HasEditPermission(CLASSES)
  const [course, setCourse] = useState({ id: location.id })
  const [courseName, setCourseName] = useState("")

  const [course_template, setCourseTemplate] = useState("")

  const [allTemplates, setAllTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [width, setWidth] = useState(1000)

  const [selectSequecing, setSelectSequecing] = useState(false)
  const [hashValue, setHashValue] = useState("real")

  const [showOnlyWorkshop, setShowOnlyWorkshop] = useState(false)

  const [selectedStage, setSelectedStage] = useState("")
  const [selectedStageName, setSelectedStageName] = useState("")
  const [modules, setModules] = useState({})
  const [moduleCreated, setModuleCreated] = useState(true)
  const [showSuggestDayModal, setShowSuggestDayModal] = useState(false)
  const [recentDay, setRecentDay] = useState(1)
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedModuleToggle, setSelectedModuleToggle] = useState({})
  const [selectedModuleToggleChange, setSelectedModuleToggleChange] = useState(false)
  const [dmodal, setDmodal] = useState(false)
  const [selectedDeletingModule, setSelectedDeletingModule] = useState([])
  const [selectedDeletingTopic, setSelectedDeletingTopic] = useState([])
  const [modulesData, setModulesData] = useState({})
  const [currentModuleFetched, setCurrentModuleFetched] = useState(false)
  const [currentModuleTopicsFetched, setCurrentModuleTopicsFetched] = useState(false)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedTopicToggle, setSelectedTopicToggle] = useState({})
  const [selectedTopicToggleChange, setSelectedTopicToggleChange] = useState(false)
  const [topicCreated, setTopicCreated] = useState(true)
  const [visibleTopicData, setVisibleTopicData] = useState({})
  const [visibleTopicDataFetched, setVisibleTopicDataFetched] = useState(false)
  const [deleteVisibleResource, setDeleteVisibleResource] = useState([])

  const [modulesResources, setModulesResources] = useState({})
  const [modulesResourcesNext, setModulesResourcesNext] = useState({})
  const [modulesResourcesFetched, setModulesResourcesFetched] = useState(false)
  const [modulesResourcesFetchedx, setModulesResourcesFetchedx] = useState(true)
  const [topicsResources, setTopicsResources] = useState({})
  const [topicsResourcesNext, setTopicsResourcesNext] = useState({})
  const [topicsResourcesNextLoader, setTopicsResourcesNextLoader] = useState({})
  const [topicsResourcesFetched, setTopicsResourcesFetched] = useState(false)
  const [topicsResourcesFetchedex, setTopicsResourcesFetchedex] = useState({})
  const [topicsResourcesFetchedxx, setTopicsResourcesFetchedxx] = useState(true)
  const [resourceDeleted, setResourceDeleted] = useState(true)
  const [collapse, setCollapse] = useState(false)

  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)
  const [topicFetched, setTopicFetched] = useState(false)
  const [topicsData, setTopicsData] = useState({})
  const [showWorkshopInfo, setShowWorkshopInfo] = useState(true)

  const [allResourcesChanged, setAllResourcesChanged] = useState(false)
  const [moduleEdited, setModuleEdited] = useState(false)
  const [courseDays, setCourseDays] = useState(0)
  const [leftNavOpen, setLeftNavOpen] = useState(false)
  const [activeResourceType, setActiveResourceType] = useState("")
  const [activeNavItem, setActiveNavItem] = useState("")

  //for suggested topics
  const [fetchSuggestedTopicsData, setFetchSuggestedTopicsData] = useState(false)
  const [showSuggestedTopicsModal, setShowSuggestedTopicsModal] = useState(false)
  const [draggableHash, setDraggableHash] = useState({
    x: 0,
    y: 0,
    width: 724,
    height: 486,
  })
  const [lessonPlanTopics, setLessonPlansTopics] = useState({})
  const [lessonPlansFetched, setLessonPlansFetched] = useState(false)
  const [lessonPlanRefetched, setLessonPlanRefetched] = useState(false)
  const [lessonLoader, setLessonLoader] = useState(false)
  const [dataUpdated, setDataUpdated] = useState(false)
  const [updateInputData, setUpdateInputData] = useState({})
  const [topicEdited, setTopicEdited] = useState(false)
  const [activeSuggestedTopicData, setActiveSuggestedTopicData] = useState(null)
  const [showDescriptionmodal, setShowDescriptionmodal] = useState(false)

  /*for system files uploading start*/
  const [highlight, setHighlight] = useState("unhighlight")
  const [files, setFiles] = useState([])
  const [refetched, setRefetched] = useState(false)
  const [hovered, setHovered] = useState(false)

  const [moduleTriggerd, setModuleTriggerd] = useState(null)
  const [topicDataModified, setTopicDataModified] = useState(false)

  const [showAddDescriptionmodal, setShowAddDescriptionModal] = useState(false)
  const [activeDatum, setActiveDatum] = useState(null)

  // for breakout session creation and edit
  const [currentNavTab, setCurrentNavTab] = useState("")
  const [dragEnded, setDragEnded] = useState(false)
  const [draggedItem, setDraggedItem] = useState("")
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("")
  const [activeSection, setActiveSection] = useState("Home")
  const [createBreakoutSessionOpened, setCreateBreakoutSessionOpened] = useState(false)
  const [selectedTopicForBreakout, setSelectedTopicForBreakout] = useState("")
  const [selectedBreakoutForEdit, setSelectedBreakoutForEdit] = useState("")
  const [createdBreakoutSession, setCreatedBreakoutSession] = useState("")

  const [uploadInTopic, setUploadInTopic] = useState(null)
  const [uploadInModule, setUploadInModule] = useState(null)
  const [uploadDataSequence, setUploadDataSequence] = useState(null)

  const [addBlog, setAddBlog] = useState(false)
  const [showPollModal, setShowPollModal] = useState(false)
  const [showPopQuizModal, setShowPopQuizModal] = useState(false)
  const [showSection, setShowSection] = useState("video")
  const quizWin = useRef(null)
  const mtfWin = useRef(null)
  const checklistWin = useRef(null)
  const casestudyWin = useRef(null)
  const [attrForResources, setAttrForResources] = useState(null)
  const [addResourceType, setAddResourceType] = useState("")

  const [breakoutGroupList, setBreakoutGroupList] = useState([])
  const [breakoutGroupsDataFetched, setBreakoutGroupsDataFetched] = useState(false)
  const [showCreatingGroupsModal, setShowCreatingGroupsModal] = useState(false)
  const [createdGroup, setCreatedGroup] = useState(true)
  const [showInstructionCreatingGroupsModal, setShowInstructionCreatingGroupsModal] = useState(false)
  const [refetchGroups, setRefetchGroups] = useState(false)

  const [showCommonItemsModal, setShowCommonItemsModal] = useState(false)
  const [openInstructions, setOpenInstructions] = useState(false)

  const [breakoutGroupsResourcesFetched, setBreakoutGroupsResourcesFetched] = useState(false)
  const [breakoutGroupResources, setBreakoutGroupResources] = useState([])
  const [breakoutGroupInstructionsFetched, setBreakoutGroupInstructionsFetched] = useState(false)
  const [breakoutGroupInstructions, setBreakoutGroupInstructions] = useState([])
  const [instructionsLoader, setInstructionsLoader] = useState({})
  const [notificationData, dispatch] = useContext(GlobalContext)
  const [disabledResources, setDisabledResources] = useState([])
  const disabledResourceType = ["mtf", "quiz", "Poll"]
  const isMuralEnabled = notificationData.appData.data?.company?.mural_enabled

  const fetchDataWs = useRef()

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    outline: "none",
    margin: "0px 0px 8px 0px",
    borderRadius: "8px",
    color: "#E0E2E5",
    background: "#303C54",
    boxShadow: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getLessonTopicItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    outline: "none",
    margin: "0px 0px 8px 0px",
    borderRadius: "9px",
    border: "1px solid transparent",
    color: "#E0E2E5",
    background: "#42506C",
    boxShadow: "0px 3px 6px #00000029",
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  function sendQuery(name, value, datum, type, template, index, group_id, instructions) {
    if (type === "group_updation") {
      updateBreakoutGroup(value, datum.id, index, instructions)
    } else {
      if (type === "topic") {
        setUpdateInputData({
          name: name,
          value: value,
          datum: datum,
          type: type,
        })
      } else {
        updateCommomResourceInstruction(value, template, datum, index, type, group_id, instructions)
      }
    }
  }

  const delayedQuery = useCallback(
    _.debounce(
      (name, value, datum, type, template, index, group_id, instructions) => sendQuery(name, value, datum, type, template, index, group_id, instructions),
      1000
    ),
    []
  )
  useEffect(() => {
    if (window && selectedTemplate) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const showSuggestedTopics = urlParams.get("suggested_topics")
      if (showSuggestedTopics === "true") {
        setFetchSuggestedTopicsData(true)
        setShowSuggestedTopicsModal(true)
      }
    }
  }, [window, selectedTemplate])

  useEffect(() => {
    if (activeNavItem === "" && activeResourceType === "") openNavWithResourceSection("Resource", "video")
  })

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", handleNewWinMessages, false)
      window.addEventListener("close-poll-modal", handlePollModalMsgs, false)
      window.addEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs, false)

      return () => {
        window.removeEventListener("message", handleNewWinMessages)
        window.removeEventListener("close-poll-modal", handlePollModalMsgs)
        window.removeEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs)
      }
    }
  }, [window])

  useEffect(() => {
    if (updateInputData && Object.keys(updateInputData).length > 0) {
      handleSaveSuggest(updateInputData.name, updateInputData.value, updateInputData.datum, updateInputData.type)
    }
  }, [Object.keys(updateInputData).length > 0])

  useEffect(() => {
    if (fetchSuggestedTopicsData) {
      fetchLessonPlanTopics()
    }
  }, [fetchSuggestedTopicsData])

  useEffect(() => {
    if (createdBreakoutSession && selectedTopicForBreakout) {
      let current_data = { ...topicsData }
      current_data["topic_" + selectedTopicForBreakout].breakout_sessions.push(createdBreakoutSession)
      setTopicsData(current_data)
      handleCloseBreakoutOverlay()
    }
  }, [createdBreakoutSession])

  async function fetchLessonPlanTopics() {
    let response = await getRequest(`/unassigned-topics/?template=${selectedTemplate}&assigned=false`)
    if (response.success) {
      let lesson_topics = { ...lessonPlanTopics }
      lesson_topics[`module_` + selectedTemplate] = {}
      lesson_topics[`module_` + selectedTemplate]["topics"] = response.data.results
      lesson_topics[`module_` + selectedTemplate]["topics_next"] = response.data.next
      setLessonPlansTopics(lesson_topics)
      setLessonPlansFetched(true)
      setLessonPlanRefetched(true)
    }
  }

  function showErrorMessage(error) {
    let error_messages = ""
    for (var key in error.response.data) {
      error_messages += error.response.data[key] + "<br>"
    }
    if (error_messages === "Invalid token.<br>") {
      authClient.signOut()
      localStorage.removeItem("authenticity_token")
      localStorage.removeItem("username")
      window.location.reload(true)
    }
    document.getElementById("fixed-error-div").innerHTML =
      '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
      '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
      '<span aria-hidden="true">×</span></button>' +
      error_messages +
      "</div>"
  }
  async function fetchNextLessonTopics(next_url) {
    setLessonLoader(false)
    let current_module_data = { ...lessonPlanTopics }
    current_module_data[`module_` + selectedTemplate].topics_loader = true
    setLessonPlansTopics(current_module_data)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let lesson_topics = { ...lessonPlanTopics }
      lesson_topics[`module_` + selectedTemplate].topics = lesson_topics[`module_` + selectedTemplate].topics.concat(res.data.results)
      lesson_topics[`module_` + selectedTemplate].topics_next = res.data.next
      lesson_topics[`module_` + selectedTemplate].topics_loader = false
      setLessonPlansTopics(lesson_topics)
      setLessonLoader(true)
    })
  }

  function handleSaveSuggest(name, value, datum, type) {
    if (datum.id && (datum.loader || datum.loader1 || datum.loader2)) {
      datum[name] = value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k] !== null) {
          if (k === "start_date" || k === "end_date") {
            if (datum[k]) {
              formData.append(k, new Date(datum[k]).toISOString())
            }
          } else if (k === "activity_workspace") {
            formData.append("activity_workspace", JSON.stringify(datum[k]))
          } else if (["owner", "suggested_by"].includes(k)) {
            formData.append(k, datum[k].id ? datum[k].id : datum[k])
          } else {
            formData.append(k, datum[k])
          }
        }
      }
      if (type) {
        setTopicEdited(false)
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}s/` + datum.id + "/?editing=true", formData, { headers: DefaultHeader })
          .then((res) => {
            if (type === "topic") {
              if (modulesData[`module_` + datum.module]) {
                let t_index = modulesData[`module_` + datum.module].topics.findIndex((res) => res.id === datum.id)
                if (t_index > -1) {
                  let t_data = modulesData
                  t_data[`module_` + datum.module].topics[t_index].name = value
                  t_data[`module_` + datum.module].topics[t_index].loader = false
                  setModulesData(t_data)
                  setTopicEdited(true)
                  let current_datam = t_data[`module_` + datum.module].topics[t_index]
                  triggerChanges(
                    `sptopicedited`,
                    datum.id + "_$_" + datum.name + `_$_` + name,
                    current_datam.session_data === null
                      ? current_datam.session_data
                      : current_datam.session_data.id + `_` + current_datam.session_data.session_type
                  )
                }
              } else if (lessonPlanTopics[`module_` + selectedTemplate]) {
                let lt_index = lessonPlanTopics[`module_` + selectedTemplate].topics.findIndex((res) => res.topic.id === datum.id)
                if (lt_index > -1) {
                  let lt_data = { ...lessonPlanTopics }
                  lt_data[`module_` + selectedTemplate].topics[lt_index].topic.name = value
                  lt_data[`module_` + selectedTemplate].topics[lt_index].topic.loader = false
                  setLessonPlansTopics(lt_data)
                  setTopicEdited(true)
                }
              }
            }
            setUpdateInputData({})
          })
          .catch((error) => {
            showErrorMessage(error)
          })
      }
    } else {
    }
  }

  useEffect(() => {
    if (lessonPlanTopics[`module_` + selectedTemplate]?.topics?.length == 0 && lessonPlanTopics[`module_` + selectedTemplate]?.topics_next !== null) {
      fetchNextLessonTopics(lessonPlanTopics[`module_` + selectedTemplate].topics_next)
    }
  }, [lessonPlanTopics[`module_` + selectedTemplate]?.topics?.length])

  useEffect(() => {
    if (showCommonItemsModal) {
      setAddResourceType("common")
    } else {
      setAddResourceType("")
    }
  }, [showCommonItemsModal])

  function showLessonTopicsButtons() {
    if (lessonPlanTopics[`module_` + selectedTemplate] && lessonPlanTopics[`module_` + selectedTemplate].topics) {
      if (lessonPlanTopics[`module_` + selectedTemplate].topics.length > 0) {
        return (
          <Droppable
            isDropDisabled={createBreakoutSessionOpened || showCommonItemsModal}
            droppableId={`module_` + selectedTemplate + `lessonPlan`}
            type={`topicDraggable`}
          >
            {(provided, snapshot) => (
              <div className={`bg-transparent`} ref={provided.innerRef} style={getTopicListStyle(snapshot.isDraggingOver)}>
                {lessonPlanTopics[`module_` + selectedTemplate].topics.map((unscheduled_topic, index) => {
                  let datum = unscheduled_topic.topic
                  return (
                    <Draggable
                      key={datum.id + `_topic_name_` + index}
                      draggableId={datum.id.toString() + "-topic" + "_" + index.toString() + "_lesson"}
                      index={index}
                      isDragDisabled={isPermitted}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`bg-transparent float-none ${StylesA.lesson_plan_topic_item}`}
                          style={getLessonTopicItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            variant="none"
                            id={"topics_" + datum.id}
                            className={`${Styles.font_16} px-2 py-1 border_on_hover shadow-none d-flex`}
                            style={{
                              color: `#F2F4FF`,
                              justifyContent: "space-between",
                              backgroundColor: "#42506C",
                              borderRadius: "9px",
                            }}
                          >
                            <div className="d-flex px-0 col-12">
                              <div
                                onClick={() => {
                                  setActiveSuggestedTopicData(unscheduled_topic)
                                  setShowDescriptionmodal(true)
                                }}
                                className={`d-inline-flex align-items-center w-100 justify-content-between`}
                              >
                                <div className={`float-left `} style={{ width: "80%" }}>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        className={`${datum.name && datum.name.length > 45 ? "" : "d-none"} classroom-tooltip`}
                                        id="tooltip-disabled-session-button"
                                      >
                                        {datum.name}
                                      </Tooltip>
                                    }
                                  >
                                    <div className="d-flex">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        name="name"
                                        id={datum.id + `_topic_input_name`}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          e.stopPropagation()
                                        }}
                                        defaultValue={datum.name}
                                        onChange={(e) => {
                                          e.preventDefault()
                                          datum.loader = true
                                          delayedQuery(e.target.name, e.target.value, datum, "topic")
                                        }}
                                        placeholder={"Activity name"}
                                        className={`${StylesA.activity_input_field} ${isPermitted && "disable-content"} form-control border-0 w-100 px-2 ${
                                          Styles.font_16
                                        }`}
                                        readOnly={isPermitted}
                                        required
                                      />
                                      {datum.loader !== undefined && datum.loader === true ? <Loader /> : null}
                                    </div>
                                  </OverlayTrigger>
                                  <p
                                    className={`mb-0 pl-2`}
                                    style={{
                                      fontSize: "10px",
                                      color: "#E0E2E5",
                                    }}
                                  >
                                    Suggested by
                                  </p>
                                  <div className={`d-inline-flex pl-2 pt-1 align-items-center`}>
                                    <FontAwesomeIcon
                                      className={`float-left`}
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                      }}
                                      icon={faUserCircle}
                                    />
                                    <div className={`float-right pl-2`}>
                                      <p
                                        className={`mb-0`}
                                        style={{
                                          fontSize: "10px",
                                          color: "#E0E2E5",
                                        }}
                                      >
                                        {unscheduled_topic?.created_by?.first_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className={`d-flex flex-column justify-content-between`}>
                                  <div
                                    className={`mb-2 border_on_hover px-1`}
                                    style={{
                                      backgroundColor: "#2F3B52",
                                      borderRadius: "6px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <FontAwesomeIcon color="#C95858" icon={faHeart} />{" "}
                                    <span style={{ color: "#E0E2E5" }} className={`pl-1`}>
                                      {unscheduled_topic?.votes?.likes}
                                    </span>
                                  </div>
                                  <div className={`mx-auto mt-auto`}>
                                    <div
                                      className={`text-center align-items-center d-flex justify-content-center pointer`}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setDmodal(true)
                                        unscheduled_topic.d_type = "lessonplan"
                                        setSelectedDeletingTopic(unscheduled_topic)
                                      }}
                                      style={{
                                        backgroundColor: "#212C42",
                                        border: "1px solid #212C42",
                                        borderRadius: "50%",
                                        height: "30px",
                                        width: "30px",
                                      }}
                                    >
                                      <img
                                        src={DeleteIcon}
                                        alt={`delete modal delete icon`}
                                        className={`my-auto pointer`}
                                        style={{
                                          objectFit: `contain`,
                                          height: `15px`,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                {lessonPlanTopics[`module_` + selectedTemplate].topics_next !== null ? (
                  <Col lg="12" className={`text-center`}>
                    <Button
                      disabled={lessonPlanTopics[`module_` + selectedTemplate].topics_loader}
                      variant="none"
                      onClick={(e) => {
                        e.preventDefault()
                        fetchNextLessonTopics(lessonPlanTopics[`module_` + selectedTemplate].topics_next)
                      }}
                      className={`${Styles.font_12} text-center px-3 py-1 d-flex mx-auto`}
                      style={{
                        backgroundColor: `#212c42`,
                        color: `#c6cff4`,
                        borderRadius: `15px`,
                      }}
                    >
                      {lessonPlanTopics[`module_` + selectedTemplate].topics_loader ? (
                        <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />
                      ) : (
                        ""
                      )}
                      See More
                    </Button>
                  </Col>
                ) : (
                  ``
                )}
              </div>
            )}
          </Droppable>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <p style={{ fontSize: "13px", margin: "0px", color: "#E0E2E5" }}>All topics added!</p>
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  useEffect(() => {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 724 - 80) / 2
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 486) / 2
      setDraggableHash({ x: x, y: y, width: 724, height: 486 })
    }
  }, [showSuggestedTopicsModal])

  function dragPosition() {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 324)
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 191)
      setDraggableHash({ x: x, y: y, width: 324, height: 191 })
    }
  }

  function onDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  function onDrop(event, module_id, id, data) {
    event.preventDefault()
    event.stopPropagation()
    setUploadInTopic(id)
    setUploadInModule(module_id)
    setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))
    setHighlight("unhighlight")
    setShowSection("video")
    handleDrop(event)
  }

  function handleDrop(e) {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: new_files[i].name + " has size more than 200MB." },
        })
      }
    }
  }

  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg( new_files[i].name + ' has size more than 200MB.')
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: new_files[i].name + " has size more than 200MB." },
        })
      }
    }
  }
  /*for system files uploading end*/

  const handleClose = () => {
    setDmodal(false)
    setSelectedDeletingModule([])
    setSelectedDeletingTopic([])
    setDeleteVisibleResource([])
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  useEffect(() => {
    if (isBrowser()) {
      setWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      return () => {
        window.removeEventListener("resize", () => {})
      }
    }
  }, [])

  useEffect(() => {
    setHovered(false)
  }, [width])

  useEffect(() => {
    fetchTemplateModules()
  }, [selectedStage])

  useEffect(() => {
    if (selectedModule != null) {
      fetchCurrentModule()
    }
  }, [selectedModule])

  useEffect(() => {
    fetchModuleResources()
  }, [selectedModule])

  useEffect(() => {
    if (modulesResourcesFetched === false) {
      setModulesResourcesFetched(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  useEffect(() => {
    if (modulesResourcesFetchedx === false) {
      setModulesResourcesFetchedx(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  useEffect(() => {
    if (currentModuleFetched) {
      fetchCurrentTopics(selectedModule)
    }
  }, [currentModuleFetched])

  useEffect(() => {
    if (hashValue === "showsequencing") {
      setSelectSequecing(true)
    }
    if (isBrowser() && hashValue !== "real") {
      window.location.hash = ""
    }
  }, [hashValue])

  useEffect(() => {
    if (course.course_type && course_template === "") {
      fetchorCreateTemplate()
    }
  }, [course])
  useEffect(() => {
    if (selectedTopic != null) {
      fetchTopicResources()
    }
  }, [selectedTopic])

  useEffect(() => {
    if (selectedTopic !== null) {
      fetchSelectedTopic(selectedTopic)
    }
  }, [selectedTopic])

  useEffect(() => {
    if (topicsResourcesFetched === false) {
      setTopicsResourcesFetched(true)
    }
  }, [topicsResources[`topic_` + selectedTopic], topicsResourcesFetchedex[`topic_` + selectedTopic]])

  useEffect(() => {
    if (topicsResourcesFetchedxx === false) {
      setTopicsResourcesFetchedxx(true)
    }
  }, [topicsResources[`topic_` + selectedTopic], topicsResourcesFetchedex[`topic_` + selectedTopic], topicsResourcesFetchedxx])

  useEffect(() => {
    let stage_data = modules[selectedStage]
    if (moduleCreated === true && stage_data && stage_data.modules.length > 0) {
      setSelectedModule(stage_data.modules[stage_data.modules.length - 1].id)
    }
  }, [moduleCreated])

  useEffect(() => {
    if (topicCreated === true && selectedModule && modulesData[selectedStage] && modulesData[selectedStage][`module_` + selectedModule]) {
      let datam = modulesData[selectedStage][`module_` + selectedModule].topics
      if (datam && datam.length > 0) {
        setSelectedTopic(datam[datam.length - 1].id)
        setCollapse(false)
        setSelectedTopic(datam[datam.length - 1].id)
      }
    }
  }, [topicCreated])

  useEffect(() => {
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }, [selectedModuleToggle[selectedModule]])

  useEffect(() => {
    setSelectedTopicToggleChange(!selectedModuleToggleChange)
  }, [selectedTopicToggle[selectedTopic]])

  useEffect(() => {
    if (selectedTemplate !== null) {
      connectSocket()
    }
    return () => {
      if (fetchDataWs && fetchDataWs.current) {
        fetchDataWs.current.close()
      }
    }
  }, [selectedTemplate])

  useEffect(() => {
    if (!moduleTriggerd) return
    var stage_id = -1
    if (course_template !== "") {
      stage_id = course_template.stages.findIndex((res) => res.name === "Workshop")
      stage_id = course_template.stages[stage_id].id
    }
    if (modules[stage_id] && Object.keys(moduleTriggerd).length > 0 && stage_id > -1 && selectedStageName === "Workshop") {
      if (moduleTriggerd.type === "module") {
        let current_modules = { ...modules }
        if (stage_id > -1) {
          let index = current_modules[stage_id].modules.findIndex((res) => res.id === moduleTriggerd.id)
          if (index > -1) {
            current_modules[stage_id].modules[index].session_type = moduleTriggerd.value
          }
          setModules(current_modules)
        }
        if (moduleTriggerd.current_module && moduleTriggerd.current_module !== "end_session" && moduleTriggerd.current_module !== "to_lesson_plan") {
          if (modulesData[`module_` + moduleTriggerd.id]) {
            // fetchCurrentTopics(true,moduleTriggerd.id,moduleTriggerd.current_module)
          }
        } else if (moduleTriggerd.current_module && moduleTriggerd.current_module === "to_lesson_plan") {
          // setCurrentModuleTopicsFetched(false)
          // fetchCurrentTopics(true,moduleTriggerd.id)
          // setLessonPlanRefetched(false)
          // fetchLessonPlanTopics(lessonPlanModules[0].id)
        }
      } else if (moduleTriggerd.type === "topic") {
        if (
          modulesData &&
          Object.keys(modulesData).length > 0 &&
          modulesData[stage_id][`module_` + moduleTriggerd.module_id] &&
          modulesData[stage_id][`module_` + moduleTriggerd.module_id].topics
        ) {
          let index = modulesData[stage_id][`module_` + moduleTriggerd.module_id].topics.findIndex((res) => res.id === parseInt(moduleTriggerd.topic_id))
          if (index > -1) {
            modulesData[stage_id][`module_` + moduleTriggerd.module_id].topics[index].topic_status = moduleTriggerd.value
          }
        }
      } else if (moduleTriggerd.type === "lptopicedited") {
        if (moduleTriggerd.session_data === null) {
          let mte_index = lessonPlanTopics[`module_` + selectedTemplate].topics.findIndex((res) => res.id === parseInt(moduleTriggerd.data.id))
          if (mte_index > -1) {
            let resource_data = { ...lessonPlanTopics }
            resource_data[`module_` + selectedTemplate].topics[mte_index][moduleTriggerd.field] = moduleTriggerd.data.name
            setLessonPlansTopics(resource_data)
          }
        } else if (
          modulesData &&
          modulesData[stage_id] &&
          modulesData[stage_id][`module_` + moduleTriggerd.session_data.id] &&
          modulesData[stage_id][`module_` + moduleTriggerd.session_data.id].topics
        ) {
          let mte_index = modulesData[stage_id][`module_` + moduleTriggerd.session_data.id].topics.findIndex(
            (res) => res.id === parseInt(moduleTriggerd.data.id)
          )
          if (mte_index > -1) {
            let resource_data = { ...modulesData }
            resource_data[stage_id][`module_` + moduleTriggerd.session_data.id].topics[mte_index][moduleTriggerd.field] = moduleTriggerd.data.name
            setModulesData(resource_data)
          }
        }
      } else if (moduleTriggerd.type === "lbtopicdeleted") {
        let mte_index = -1
        if (
          modulesData &&
          modulesData[stage_id] &&
          modulesData[stage_id][`module_` + moduleTriggerd.previos_module] &&
          modulesData[stage_id][`module_` + moduleTriggerd.previos_module].topics
        ) {
          mte_index = modulesData[stage_id][`module_` + moduleTriggerd.previos_module].topics.findIndex((res) => res.id === parseInt(moduleTriggerd.id))
        } else {
          mte_index = -2
        }
        if (mte_index > -1) {
          let resource_data = { ...modulesData }
          if (moduleTriggerd.current_module === "to_lesson_plan") {
            resource_data[stage_id][`module_` + moduleTriggerd.previos_module].topics[mte_index].session_data = null
          } else {
            resource_data[stage_id][`module_` + moduleTriggerd.previos_module].topics[mte_index].session_data.id = moduleTriggerd.current_module
          }
          let [current_topic] = resource_data[stage_id][`module_` + moduleTriggerd.previos_module].topics
          if (moduleTriggerd.current_module === "to_lesson_plan") {
            let lesson_resource_data = { ...lessonPlanTopics }
            if (Object.keys(lesson_resource_data).length > 0 && lesson_resource_data[`module_` + selectedTemplate]) {
              lesson_resource_data[`module_` + selectedTemplate].topics.push(current_topic)
              setLessonPlansTopics(lesson_resource_data)
            }
          } else {
            if (
              resource_data[stage_id][`module_` + moduleTriggerd.current_module] &&
              resource_data[stage_id][`module_` + moduleTriggerd.current_module].topics
            ) {
              resource_data[stage_id][`module_` + moduleTriggerd.current_module].topics.push(current_topic)
            }
          }
          resource_data[stage_id][`module_` + moduleTriggerd.previos_module].topics.splice(mte_index, 1)
          setModulesData(resource_data)
        }
        if (mte_index === -2) {
          if (moduleTriggerd.current_module === "to_lesson_plan") {
            setLessonPlanRefetched(false)
            fetchLessonPlanTopics()
          }
        }
      } else if (moduleTriggerd.type === "lbsessiondeleted") {
        let current_modules = modules[selectedStage].modules
        if (modules[selectedStage] && current_modules.length > 0) {
          let index = modules[selectedStage].modules.findIndex((res) => res.id === moduleTriggerd.id)
          current_modules.splice(index, 1)
        }
        if (moduleTriggerd.value === "to_lesson_plan") {
          setLessonPlanRefetched(false)
          fetchLessonPlanTopics()
        } else if (moduleTriggerd.value > 0) {
          // setCurrentModuleTopicsFetched(false)
          // fetchCurrentTopics(moduleTriggerd.value,true)
        }
      } else if (moduleTriggerd.type === "lbtopicreordred" && modulesData && modulesData[stage_id]) {
        setCurrentModuleTopicsFetched(false)
        fetchCurrentTopics(moduleTriggerd.id, true)
      } else if (moduleTriggerd.type === "lbsessionedited") {
        let current_modules = { ...modules }
        if (current_modules && current_modules[selectedStage] && current_modules[selectedStage].modules && current_modules[selectedStage].modules.length > 0) {
          let index = modules[selectedStage].modules.findIndex((res) => res.id === parseInt(moduleTriggerd.data.id))
          if (index > -1) {
            if (moduleTriggerd.field === "start_date") {
              current_modules[selectedStage].modules[index][moduleTriggerd.field] = new Date(parseInt(moduleTriggerd.data.name))
            } else {
              current_modules[selectedStage].modules[index][moduleTriggerd.field] = moduleTriggerd.data.name
            }
            setModules(current_modules)
          }
        }
      } else {
        // Based on event type calling function dynamically
        try {
          const event_fn = eval(`${moduleTriggerd.event_type}FromWebsocket`)
          event_fn(moduleTriggerd)
        } catch (error) {
          // pass
        }
      }
    }
  }, [moduleTriggerd])

  function connectSocket() {
    if (authClient.isAuthenticated()) {
      let updateSocket = WsConnect(`/ws/fetch-data/${selectedTemplate}/`)
      updateSocket.onmessage = function (e) {
        let data = JSON.parse(e.data)
        if (data.value === "echo") return
        let responseData = JSON.parse(data.value)
        if (responseData.fetch_type?.indexOf("spsessionupdate") > -1) {
          setModuleTriggerd({
            id: responseData.id,
            value: responseData.value.split("_$_")[0],
            type: "module",
            current_module: responseData.value.split("_$_")[1],
          })
        } else if (responseData.fetch_type?.indexOf("topic_") > -1) {
          setModuleTriggerd({
            topic_id: responseData.fetch_type.split("topic_")[1],
            module_id: responseData.id,
            value: responseData.value.split("_$_")[0],
            type: "topic",
          })
        } else if (responseData.fetch_type?.indexOf("lessonresources") > -1) {
          setModuleTriggerd({
            id: responseData.id,
            type: "resources",
            value: responseData.value,
          })
        } else if (responseData.fetch_type?.indexOf("lessonplanTopicCreated") > -1) {
          setModuleTriggerd({ type: "lessonplanTopicCreated" })
        } else if (responseData.fetch_type?.indexOf("lptopicdeleted") > -1) {
          setModuleTriggerd({
            type: "lptopicdeleted",
            id: responseData.id,
            value: responseData.value,
          })
        } else if (responseData.fetch_type?.indexOf("lptopicedited") > -1 || responseData.fetch_type?.indexOf("lbtopicedited") > -1) {
          setModuleTriggerd({
            field: responseData.id.split("_$_")[2],
            type: "lptopicedited",
            data: {
              id: responseData.id.split("_$_")[0],
              name: responseData.id.split("_$_")[1],
            },
            session_data:
              responseData.value === null
                ? null
                : {
                    id: responseData.value.split("_")[0],
                    session_type: responseData.value.split("_")[1],
                  },
          })
        } else if (responseData.fetch_type?.indexOf("lbsessionedited") > -1) {
          setModuleTriggerd({
            field: responseData.value,
            type: "lbsessionedited",
            data: {
              id: responseData.id.split("_$_")[0],
              name: responseData.id.split("_$_")[1],
            },
          })
        } else if (responseData.fetch_type?.indexOf("lbtopicreordred") > -1) {
          setModuleTriggerd({ type: "lbtopicreordred", id: responseData.value.split("_")[0] })
        } else if (responseData.fetch_type?.indexOf("lbsessiondeleted") > -1) {
          setModuleTriggerd({
            type: "lbsessiondeleted",
            id: responseData.id,
            value: responseData.value,
          })
        } else if (responseData.fetch_type?.indexOf("lbtopicdeleted") > -1) {
          setModuleTriggerd({
            type: "lbtopicdeleted",
            id: responseData.id.split("_$_")[0],
            previos_module: responseData.id.split("_$_")[1],
            current_module: responseData.value,
          })
        } else {
          setModuleTriggerd(responseData)
        }
      }
      updateSocket.onclose = function (e) {
        if (!e.wasClean) {
          setTimeout(connectSocket(), 5000)
        }
      }

      updateSocket.onopen = (e) => {
        fetchDataWs.current = updateSocket
      }
    }
  }

  // Handle websocket trigger for start or end of a session in live class.
  const sessionStatusChangedFromWebsocket = (data) => {
    let stage = course_template?.stages?.filter((res) => res.name === "Workshop")
    if (stage && stage.length > 0 && stage[0].id) {
      stage = stage[0]
      let current_modules = { ...modules }
      let changed_module = current_modules[stage.id]?.modules?.filter((res) => res.id === data.id)
      if (changed_module && changed_module.length > 0) {
        changed_module[0][data.name] = data.value
        setModules(current_modules)
        // When a session is ended and trainer moved the pending topics to next module or unassigned topics, we have to refetch the current and next module topics.
        if (data.value === "2" && modulesData[stage.id]) {
          if (data.session_end_type === "to_lesson_plan" || data.session_end_type === "to_next_session") {
            // We have to refetch topics only if it's already fetched. Otherwise it will be fetched once user expands the topic
            if (modulesData[stage.id][`module_${data.id}`]) {
              fetchCurrentTopics(data.id, true)
            }
            let session_index = current_modules[stage.id].modules.findIndex((res) => res.id === data.id)
            let next_session = current_modules[stage.id].modules[session_index + 1]
            // We have to refetch topics only if it's already fetched. Otherwise it will be fetched once user expands the topic
            if (data.session_end_type === "to_next_session" && modulesData[stage.id][`module_${next_session.id}`]) {
              fetchCurrentTopics(next_session.id, true)
            }
          }
        }
      }
    }
  }

  async function fetchSelectedTopic(selectedTopic, refetch = false) {
    if (selectedTopic) {
      if ((topicsData && topicsData["topic_" + selectedTopic] === undefined) || refetch) {
        axios
          .get(process.env.GATSBY_REACT_APP_API_HOST + `/topic-basic-detail/${selectedTopic}/`, { headers: login_credentials })
          .then((res) => {
            let data = { ...topicsData }
            data["topic_" + selectedTopic] = res.data
            setTopicsData(data)
            setTopicFetched(true)
          })
          .catch((error) => {
            setTopicFetched(false)
            showErrorMessage(error)
          })
      } else {
        setTopicFetched(true)
      }
    }
  }

  async function fetchModuleResources() {
    if (selectedModule !== null && modulesResources[`module_` + selectedModule] === undefined) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/resources-names-list/?module=${selectedModule}&paginate_by=10&draft_content=${hashValue === "real" ? `false` : `true`}`,
          { headers: login_credentials }
        )
        .then((res) => {
          modulesResources[`module_` + selectedModule] = res.data.results
          modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
        })
    }
  }

  async function fetchTopicResources(current_topic = null) {
    let currentTopic = current_topic === null ? selectedTopic : current_topic
    if ((currentTopic !== null && topicsResources[`topic_` + currentTopic] === undefined) || current_topic !== null) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/resources-names-list/?topic=${currentTopic}&paginate_by=10&draft_content=${hashValue === "real" ? `false` : `true`}`,
          { headers: login_credentials }
        )
        .then((res) => {
          topicsResources[`topic_` + currentTopic] = res.data.results
          topicsResourcesFetchedex[`topic_` + currentTopic] = true
          topicsResourcesNext[`topic_` + currentTopic] = res.data.next === null ? {} : { next: res.data.next }
          setTopicsResourcesFetched(false)
          if (current_topic !== null) {
            setRefetched(!refetched)
          }
          setUploadInTopic(null)
          setUploadInModule(null)
          setUploadDataSequence(null)
        })
    }
  }

  async function fetchNextTopicResources(next_url, current_topic = null) {
    let currentTopic = current_topic === null ? selectedTopic : current_topic
    let resources_seemore_loader = { ...topicsResourcesNextLoader }
    resources_seemore_loader[`topic_` + currentTopic] = true
    setTopicsResourcesNextLoader(resources_seemore_loader)
    setTopicsResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_topic_resources = topicsResources[`topic_` + currentTopic]
      if (res.data.results.length > 0) {
        for (var tprs = 0; tprs <= res.data.results.length - 1; tprs++) {
          next_topic_resources.push(res.data.results[tprs])
        }
        topicsResources[`topic_` + currentTopic] = next_topic_resources
      }
      topicsResourcesNext[`topic_` + currentTopic] = res.data.next === null ? {} : { next: res.data.next }
      setTopicsResourcesFetched(true)
      let resources_seemore_loader = { ...topicsResourcesNextLoader }
      resources_seemore_loader[`topic_` + currentTopic] = false
      setTopicsResourcesNextLoader(resources_seemore_loader)
    })
  }

  function deleteResource(id, push_to_bucket = false, item = {}, topic_id) {
    let value = ""
    if (hashValue !== "real") {
      value = `?draft_content=true&stage_id=${selectedStage}`
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/" + value, { headers: DefaultHeader })
      .then((res) => {
        let type = "topic"
        if (type === "topic") {
          // triggerChanges('sessionresources',topic_id)
        }
        if (topic_id !== null) {
          triggerChanges("lessonresources", topic_id)
        }
        setResourceDeleted(true)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  async function fetchNextModuleResources(next_url) {
    setModulesResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_module_resources = modulesResources[`module_` + selectedModule]
      if (res.data.results.length > 0) {
        for (var mrs = 0; mrs <= res.data.results.length - 1; mrs++) {
          next_module_resources.push(res.data.results[mrs])
        }
        modulesResources[`module_` + selectedModule] = next_module_resources
      }
      modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
      setModulesResourcesFetched(true)
    })
  }

  async function fetchorCreateTemplate() {
    // setCompanyLoader(false)
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/default-course-templates/fetch_or_create/${location.id}/?draft_content=${hashValue === "real" ? `false` : `true`}&training=true`,
        { headers: login_credentials }
      )
      .then((res) => {
        setCourseTemplate(res.data)

        if (res.data.stages.length > 0) {
          if (course.course_type === "1" || course.course_type === "3") {
            setShowOnlyWorkshop(true)
          }
          setSelectedStageName(res.data.stages[0].name)
          setSelectedStage(res.data.stages[0].id)
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  async function fetchCourse() {
    // setCompanyLoader(false)
    let res = await getRequest(`/class-content/${location.id}/?draft_content=${hashValue === "real" ? `false` : `true`}`)
    if (res.success) {
      if (res.data.duration > 0) {
        setCourseDays(Math.ceil(res.data.duration / 24))
      }
      setCourse(res.data)
      let str = res.data.name
      if (str.length > 23) {
        let res1 = str.substring(0, 22)
        let res2 = res1 + "..."
        setCourseName(res2)
      } else {
        setCourseName(str)
      }
      if (res.data.draft_template_id && res.data.draft_template_id !== null) {
        setSelectedTemplate(res.data.draft_template_id ? res.data.draft_template_id : null)
      } else {
        setSelectedTemplate(res.data.training_activity_template_id ? res.data.training_activity_template_id : null)
      }
    }
  }
  async function fetchTemplateModules() {
    if (selectedStage && modules[selectedStage] === undefined) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/basic-modules-list/?stage=${selectedStage}&paginate_by=15&draft_content=${hashValue === "real" ? `false` : `true`}`,
          { headers: login_credentials }
        )
        .then((res) => {
          let current_modules = { ...modules }
          current_modules[selectedStage] = {}
          current_modules[selectedStage].modules = res.data.results
          current_modules[selectedStage].loader = false
          current_modules[selectedStage].next_url = res.data.next
          if (res.data.next) {
            // current_modules[selectedStage].next_loader = true
            // fetchNextTemplateModules(res.data.next)
          } else {
            current_modules[selectedStage].next_loader = false
          }
          setModules(current_modules)
          if (res.data.results.length === 0 && selectedStageName !== "Workshop") {
            setModuleCreated(false)
            createModule(null)
            setCurrentModuleFetched(true)
          }
        })
    }
  }
  async function fetchNextTemplateModules(next_url) {
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_modules = { ...modules }
      current_modules[selectedStage].modules = modules[selectedStage].modules.concat(res.data.results)
      current_modules[selectedStage].loader = false
      current_modules[selectedStage].next_url = res.data.next
      setModules(current_modules)
      if (res.data.next) {
        current_modules[selectedStage].next_loader = true
        fetchNextTemplateModules(res.data.next)
      } else {
        current_modules[selectedStage].next_loader = false
      }
    })
  }
  async function fetchCurrentModule(s_module = null) {
    let current_module = s_module !== null ? s_module : selectedModule
    if (modulesData[selectedStage] === undefined || (modulesData[selectedStage] && modulesData[selectedStage][`module_` + current_module] === undefined)) {
      setCurrentModuleFetched(false)
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/module-basic-detail/${current_module}/`, { headers: login_credentials })
        .then((res) => {
          let current_data = { ...modulesData }
          if (current_data[selectedStage] === undefined) {
            current_data[selectedStage] = {}
          }
          current_data[selectedStage][`module_` + current_module] = res.data
          current_data[selectedStage][`module_` + current_module].loader = false
          setModulesData(current_data)
          setCurrentModuleTopicsFetched(true)
          if (s_module === null) {
            setCurrentModuleFetched(true)
          } else {
            setSelectedModule(current_module)
            setCurrentModuleFetched(true)
          }
        })
        .catch((error) => {
          setCurrentModuleFetched(false)
          showErrorMessage(error)
        })
    } else {
      setCurrentModuleFetched(true)
      setCurrentModuleTopicsFetched(true)
    }
  }
  async function fetchCurrentTopics(s_module, refetch = false) {
    let stage_data = modulesData[selectedStage]
    let current_module = s_module
    let is_plan = selectedStageName === "Workshop" ? `&is_plan=true` : ``
    if ((stage_data && stage_data[`module_` + current_module] && stage_data[`module_` + current_module].topics === undefined) || refetch) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/basic-topics-list/?module=${current_module}&paginate_by=15${is_plan}&draft_content=${hashValue === "real" ? `false` : `true`}`,
          { headers: login_credentials }
        )
        .then((res) => {
          let current_data = { ...modulesData }
          current_data[selectedStage][`module_` + current_module].topics = res.data.results
          current_data[selectedStage][`module_` + current_module].topics_next = res.data.next
          current_data[selectedStage].topics_loader = false
          setSelectedTopic(null)
          setCurrentModuleTopicsFetched(true)
          if (res.data.results.length > 0) {
            let first_topic = res.data.results[0]
            setSelectedTopic(first_topic.id)
            toggleTopic(null, first_topic.id)
          }
          if (res.data.next) {
            current_data[selectedStage].topics_next_loader = true
            fetchNextCurrentTopics(res.data.next)
          } else {
            current_data[selectedStage].topics_next_loader = false
          }
          setModulesData(current_data)
          if (
            res.data.results.length === 0 &&
            modulesData[selectedStage] &&
            modulesData[selectedStage][`module_` + current_module] &&
            modulesData[selectedStage][`module_` + current_module].module_type !== "5"
          ) {
            setTopicCreated(false)
            let current_selectedModuleToggle = { ...selectedModuleToggle }
            current_selectedModuleToggle[selectedModule] = true
            setSelectedModuleToggle(current_selectedModuleToggle)
            createTopic(null, selectedModule, null, true)
          }
        })
        .catch((error) => {
          setCurrentModuleTopicsFetched(false)
          showErrorMessage(error)
        })
    } else {
      setSelectedTopic(null)
      setCurrentModuleTopicsFetched(true)
    }
  }
  async function fetchNextCurrentTopics(next_url) {
    let current_data = { ...modulesData }
    current_data[selectedStage][`module_` + selectedModule].topics_next_loader = true
    setModulesData(current_data)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_data = { ...modulesData }
      current_data[selectedStage][`module_` + selectedModule].topics = current_data[selectedStage][`module_` + selectedModule].topics.concat(res.data.results)
      current_data[selectedStage][`module_` + selectedModule].topics_next = res.data.next
      current_data[selectedStage][`module_` + selectedModule].topics_next_loader = false
      setModulesData(current_data)
      setCurrentModuleTopicsFetched(true)
    })
  }

  function triggerChanges(fetch_type, id = null, value = null) {
    if (fetch_type && selectedStageName === "Workshop") {
      let datum_id = id === null ? `` : `?id=${id}`
      let value_data = value ? `&value=${value}` : ``
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/lesson-board-triggering/${course_template.id}/${fetch_type}/${datum_id}${value_data}`, {
          headers: login_credentials,
        })
        .then((res) => {})
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }

  function handleSave(name, value, datum, type, delete_topic = false) {
    setModuleEdited(false)
    if (datum.id) {
      datum[name] = value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k] !== null && k !== "suggested_by") {
          if (k === "start_date" || k === "end_date") {
            if (datum[k]) {
              formData.append(k, new Date(datum[k]).toISOString())
            }
          } else if (k === "owner") {
            formData.append(k, localStorage.getItem("student_id"))
          } else if (k === "activity_workspace") {
            formData.append("activity_workspace", JSON.stringify(datum[k]))
          } else {
            formData.append(k, datum[k])
          }
        }
      }
      // for drafting
      if (type === "module" && hashValue !== "real") {
        formData.append(`stage_id`, selectedStage)
      } else if (type === "topic") {
        if (selectedStageName === "Workshop") {
          if (!delete_topic) {
          } else if (delete_topic === "add_to_do" || delete_topic === "add_to_do_from_lp") {
            formData.append("duplicate", delete_topic)
          } else {
            formData.append("duplicate", false)
          }
        }
      } else if (type === "resource" && hashValue !== "real") {
        formData.append(`stage_id`, selectedStage)
      }
      // for end
      if (type) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}s/` + datum.id + `/`, formData, { headers: DefaultHeader })
          .then((res) => {
            setModuleEdited(true)
            if (type === "module" && name === "sequence") {
              triggerChanges("lbsessions")
            } else if (selectedStageName === "Workshop" && type === "module") {
              let updated_data_value = res.data[name]
              let updated_data = `${datum.id}_$_${encodeURIComponent(updated_data_value)}`
              triggerChanges(`spsessionedited`, updated_data, name)
            } else if (selectedStageName === "Workshop" && type === "topic") {
              let stage_data = modulesData[selectedStage]
              let t_index = -1
              if (res.data.session_data && res.data.session_data.id) {
                t_index = stage_data[`module_` + res.data.session_data.id].topics.findIndex((res) => res.id === datum.id)
              }
              let current_datam = null
              if (t_index > -1) {
                current_datam = stage_data[`module_` + res.data.session_data.id].topics[t_index]
              }
              if (selectedStageName === "Workshop" && name === "sequence" && t_index > -1 && current_datam !== null) {
                let session_datam = ""
                if (res.data.session_data && res.data.session_data.id && res.data.session_data.session_type) {
                  session_datam = res.data.session_data.id + `_` + res.data.session_data.session_type
                  if (delete_topic === "add_to_do" && current_datam.previos_module > 0) {
                    session_datam = session_datam + `_` + current_datam.previos_module
                  }
                  triggerChanges(
                    `todo${delete_topic === "add_to_do" && current_datam.previos_module > 0 ? `` : res.data.session_data.session_type}`,
                    datum.id,
                    session_datam
                  )
                }
              } else if (selectedStageName === "Workshop" && t_index > -1 && current_datam !== null) {
                triggerChanges(
                  `sptopicedited`,
                  datum.id + "_$_" + datum.name + `_$_` + name,
                  res.data.session_data ? res.data.session_data.id + `_` + res.data.session_data.session_type : res.data.session_data
                )
              } else if (selectedStageName === "Workshop" && datum.session_data) {
                triggerChanges("todo" + datum.session_data.session_type + `__` + datum.session_data.id, datum.id, null)
              }

              if (type === "topic" && res.data.session_data) {
                datum.session_data = res.data.session_data
              }
            }
          })
          .catch((error) => {
            showErrorMessage(error)
          })
      }
    } else {
    }
  }
  function selectModuleName(type) {
    if (type === "5") {
      if (course.course_type === "1") {
        return "Break"
      } else {
        return "Module"
      }
    } else if (selectedStageName === "Workshop" && course.course_type !== "1") {
      return `Session `
    } else {
      return `Module `
    }
  }

  const changeCurrentSession = (topic_id, value) => {
    let data = { ...topicsData }
    data[`topic_${topic_id}`].breakout_sessions = value
    setTopicsData(data)
  }

  function calculateModuleCount(modules) {
    if (selectedStageName === "Workshop") {
      if (course.course_type === "3" || course.course_type === "0") {
        if (modules.length > 0) {
          return modules.filter((module) => module.module_type === "3").length
        } else return 0
      } else {
        return modules.length
      }
    } else {
      return modules.length
    }
  }
  function setSuggestedDay(e, suggestedDay, type = "", index_for_sequence = -1) {
    let module_length = modules[selectedStage] ? modules[selectedStage].modules : []
    let sequence = (calculateModuleCount(module_length) + 1) * 10
    let final_sequence = (1 * 10).toFixed(20)
    if (index_for_sequence > -1) {
      let second_sequence = parseFloat(module_length[index_for_sequence].sequence)
      if (module_length[index_for_sequence - 1]) {
        let first_sequence = parseFloat(module_length[index_for_sequence - 1].sequence)
        final_sequence = ((first_sequence + second_sequence) / 2).toFixed(20)
      } else {
        final_sequence = (second_sequence - 10).toFixed(20)
      }
    } else if (module_length.length > 0) {
      final_sequence = (parseFloat(module_length[module_length.length - 1].sequence) + 10).toFixed(20)
    }
    let module = {}
    module.name = selectModuleName(type)
    module.name = type ? module.name : module.name + ` ${sequence / 10}`
    module.sequence = final_sequence
    module.add_to_stage = selectedStage
    module.published = true
    module.module_type = type ? type : selectedStageName === "Workshop" ? "3" : "0"
    if (selectedStageName === "Workshop" && course.course_type === "0") {
      module.course_day = suggestedDay
    }
    var formData = new FormData()
    if (hashValue !== "real") {
      formData.append("draft_content", "true")
    }
    for (var k in module) {
      formData.append(k, module[k])
    }
    if (module.id !== undefined) {
    } else {
      setModuleCreated(false)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/modules/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            new_data.published = res.data.published
            new_data.duration = res.data.duration
            new_data.reminder_time = res.data.reminder_time
            new_data.recurring_reminder = res.data.recurring_reminder
            new_data.start_date = res.data.start_date
            new_data.breakout_session_distribution_type = res.data.breakout_session_distribution_type
            new_data.topic_count = res.data.topics.length
            new_data.module_type = res.data.module_type
            new_data.course_day = res.data.course_day
            let current_modules = { ...modules }
            if (current_modules[selectedStage] === undefined) {
              current_modules[selectedStage] = {}
              current_modules[selectedStage].modules = [new_data]
              current_modules[selectedStage].loader = false
              current_modules[selectedStage].next_url = null
              current_modules[selectedStage].next_loader = false
            } else {
              if (index_for_sequence > -1) {
                let new_index = index_for_sequence - 1 > -1 ? index_for_sequence - 1 : 0
                if (new_index === -1) {
                  current_modules[selectedStage].modules.unshift(new_data)
                } else {
                  modules[selectedStage].modules.splice(index_for_sequence, 0, new_data)
                }
              } else {
                current_modules[selectedStage].modules = modules[selectedStage].modules.concat(new_data)
              }
            }
            setModules(current_modules)
            setSelectedModule(res.data.id)
            if ((res.data.module_type === "5" && course.course_type === "3") || res.data.module_type !== "5") {
              toggleModule(e, res.data.id)
            }
            scrollToSpecific(e, "modules_" + res.data.id, "module")
            setModuleCreated(true)
            setShowSuggestDayModal(false)
          }
        })
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }
  function getTimeDifference(start_date, end_date, type) {
    let s_hours = utcDateTime.utcDateTime(start_date, "hour")
    let e_hours = utcDateTime.utcDateTime(end_date, "hour")
    let s_min = utcDateTime.utcDateTime(start_date, "minute")
    let e_min = utcDateTime.utcDateTime(end_date, "minute")
    let hour_diff = s_hours > e_hours ? e_hours - s_hours + 24 : e_hours - s_hours
    let min_diff = s_min > e_min ? e_min - s_min + 60 : e_min - s_min
    if (type === "hour") {
      return hour_diff
    } else {
      return min_diff
    }
  }
  function suggestDayForNextSession() {
    let allSessions = modules[selectedStage].modules.filter((module) => module.module_type === "3")
    let lastSession = allSessions[allSessions.length - 1]
    let lastSesionDay = lastSession.course_day
    let totalHoursPerDay = 0
    let totalMin = 0
    for (let i = 0; i < allSessions.length; i++) {
      if (allSessions[i].course_day === lastSesionDay) {
        totalHoursPerDay = totalHoursPerDay + getTimeDifference(allSessions[i].start_date, allSessions[i].end_date, "hour")
        totalMin = totalMin + getTimeDifference(allSessions[i].start_date, allSessions[i].end_date, "min")
      }
    }
    if (totalMin >= 60) {
      totalHoursPerDay = totalHoursPerDay + (totalMin % 60)
      totalMin = totalMin / 60
    }
    if (totalHoursPerDay >= 8 && lastSesionDay <= courseDays) {
      setShowSuggestDayModal(true)
      setRecentDay(lastSesionDay)
      return true
    } else {
      return false
    }
  }
  function createModule(e, type = "", index_for_sequence = -1) {
    if (e) {
      e.preventDefault()
    }
    let flag = false
    if (modules[selectedStage] && modules[selectedStage].modules.length > 0 && selectedStageName === "Workshop" && course.course_type == "0" && type !== "5") {
      flag = suggestDayForNextSession()
    }
    if (!flag) {
      let lastSesionDay = 1
      if (modules[selectedStage] && modules[selectedStage].modules.length > 0 && selectedStageName === "Workshop" && course.course_type == "0") {
        let allSessions = modules[selectedStage].modules
        let lastSession = allSessions[allSessions.length - 1]
        lastSesionDay = lastSession.course_day
      } else {
        lastSesionDay = 1
      }
      let module_length = modules[selectedStage] ? modules[selectedStage].modules : []
      let sequence = (calculateModuleCount(module_length) + 1) * 10
      let final_sequence = (1 * 10).toFixed(20)
      if (index_for_sequence > -1) {
        let second_sequence = parseFloat(module_length[index_for_sequence].sequence)
        if (module_length[index_for_sequence - 1]) {
          let first_sequence = parseFloat(module_length[index_for_sequence - 1].sequence)
          final_sequence = ((first_sequence + second_sequence) / 2).toFixed(20)
        } else {
          final_sequence = (second_sequence - 10).toFixed(20)
        }
      } else if (module_length.length > 0) {
        final_sequence = (parseFloat(module_length[module_length.length - 1].sequence) + 10).toFixed(20)
      }
      let module = {}
      module.name = selectModuleName(type)
      module.name = type ? module.name : module.name + ` ${sequence / 10}`
      module.sequence = final_sequence
      module.add_to_stage = selectedStage
      module.published = true
      module.module_type = type ? type : selectedStageName === "Workshop" ? "3" : "0"
      if (selectedStageName === "Workshop" && course.course_type === "0") {
        module.course_day = lastSesionDay
      }
      var formData = new FormData()
      if (hashValue !== "real") {
        formData.append("draft_content", "true")
      }
      for (var k in module) {
        formData.append(k, module[k])
      }
      if (module.id !== undefined) {
      } else {
        setModuleCreated(false)
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/modules/create/", formData, { headers: login_credentials })
          .then((res) => {
            let new_data = {}
            // let datum = modules
            if (res.data) {
              new_data.id = res.data.id
              new_data.name = res.data.name
              new_data.sequence = res.data.sequence
              new_data.published = res.data.published
              new_data.duration = res.data.duration
              new_data.reminder_time = res.data.reminder_time
              new_data.recurring_reminder = res.data.recurring_reminder
              new_data.start_date = res.data.start_date
              new_data.breakout_session_distribution_type = res.data.breakout_session_distribution_type
              new_data.topic_count = res.data.topics.length
              new_data.module_type = res.data.module_type
              new_data.course_day = res.data.course_day
              new_data.session_type = res.data.session_type
              let current_modules = { ...modules }
              if (current_modules[selectedStage] === undefined) {
                current_modules[selectedStage] = {}
                current_modules[selectedStage].modules = [new_data]
                current_modules[selectedStage].loader = false
                current_modules[selectedStage].next_url = null
                current_modules[selectedStage].next_loader = false
              } else {
                if (index_for_sequence > -1) {
                  let new_index = index_for_sequence - 1 > -1 ? index_for_sequence - 1 : 0
                  if (new_index === -1) {
                    current_modules[selectedStage].modules.unshift(new_data)
                  } else {
                    modules[selectedStage].modules.splice(index_for_sequence, 0, new_data)
                  }
                } else {
                  current_modules[selectedStage].modules = modules[selectedStage].modules.concat(new_data)
                }
              }
              setModules(current_modules)
              setSelectedModule(res.data.id)
              if ((res.data.module_type === "5" && course.course_type === "3") || res.data.module_type !== "5") {
                toggleModule(e, res.data.id)
              }
              scrollToSpecific(e, "modules_" + res.data.id, "module")
              setModuleCreated(true)
              triggerChanges("lbsessions")
            }
          })
          .catch((error) => {
            showErrorMessage(error)
          })
      }
    }
  }
  function scrollToSpecific(e, value, type, topic_id = "") {
    if (e !== null) {
      e.preventDefault()
    }
    if (isBrowser() && value !== "" && document.getElementById(value) && document.getElementById("scrollable-div")) {
      let scroll_value = 0
      if (type === "module") {
        scroll_value = document.getElementById("scrollable-div").scrollHeight
      } else {
        let topic_div = document.getElementById(topic_id).getBoundingClientRect()
        let current_scroll = document.getElementById("scrollable-div").scrollTop
        scroll_value = current_scroll + topic_div.top - 150
      }
      setTimeout(function () {
        document.getElementById("scrollable-div").scroll({
          behavior: "smooth",
          left: 0,
          top: scroll_value,
        })
      }, 200)
    }
  }
  function createTopic(e, module_id, draggedItem = null, from_auto_create = false) {
    let topic = {}
    if (e) {
      e.preventDefault()
    } else {
      topic.visible_as_resource = true
    }
    let topics = modulesData[selectedStage][`module_` + module_id].topics
    let sequence = (topics.length + 1) * 10
    topic.name = course.course_type === "3" && selectedStageName === "Workshop" ? "Lesson " : "Topic " + sequence / 10
    topic.sequence = sequence
    topic.add_to_module = module_id
    topic.published = true
    var formData = new FormData()
    for (var k in topic) {
      formData.append(k, topic[k])
    }
    if (hashValue !== "real") {
      formData.append("draft_content", "true")
      formData.append(`stage_id`, selectedStage)
    }
    if (topic.id !== undefined) {
    } else {
      formData.append("duplicate", "add_to_do")
      setTopicCreated(false)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/topics/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            new_data.visible_as_resource = res.data.visible_as_resource
            new_data.published = res.data.published
            new_data.weightage = res.data.weightage
            new_data.duration = res.data.duration
            new_data.session_data = res.data.session_data
            new_data.breakout_sessions = res.data.breakout_sessions
            let current_data = { ...modulesData }
            current_data[selectedStage][`module_` + module_id].topics = current_data[selectedStage][`module_` + module_id].topics.concat(new_data)
            setModulesData(current_data)
            let current_modules = { ...modules }
            let selected_module = current_modules[selectedStage].modules.filter((module) => module.id === module_id)[0]
            selected_module["topic_count"] = selected_module.topic_count + 1
            let module_index = current_modules[selectedStage].modules.indexOf(selected_module)
            current_modules[module_index] = selectedModule
            setModules(current_modules)
            toggleTopic(e, res.data.id)
            setCurrentModuleTopicsFetched(true)
            setTopicCreated(true)
            if (!from_auto_create) {
              scrollToSpecific(e, "modules_" + module_id, "topic", "topics_" + res.data.id)
            }
            if (e === null && !from_auto_create) {
              setVisibleTopicData({})
              setVisibleTopicDataFetched(!visibleTopicDataFetched)
            }
            if (selectedStageName === "Workshop") {
              triggerChanges("todo" + res.data.session_data.session_type, res.data.id, res.data.session_data.id + `_` + res.data.session_data.session_type)
            }
          }
        })
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }
  function toggleTopic(e, datum_id) {
    if (e) {
      e.preventDefault()
    }
    selectedTopicToggle[datum_id] = !selectedTopicToggle[datum_id]
    setSelectedTopicToggleChange(!selectedTopicToggleChange)
  }

  const deleteSuggestedTopic = async () => {
    let response = await deleteRequest(`/unassigned-topics/${selectedDeletingTopic.id}/`)
    if (response.success) {
      let index = lessonPlanTopics[`module_` + selectedTemplate].topics.findIndex((res) => res.id === selectedDeletingTopic.id)
      let suggested_topics = { ...lessonPlanTopics }
      suggested_topics[`module_` + selectedTemplate].topics.splice(index, 1)
      setLessonPlansTopics(suggested_topics)
      setSelectedDeletingTopic([])
      setDmodal(!dmodal)
    }
  }

  async function deleteModule() {
    let url = selectedDeletingModule && selectedDeletingModule.id ? `modules` : `topics`
    if (url === "modules") {
      var current_id = selectedDeletingModule.id
      var index = modules[selectedStage].modules.findIndex((res) => res.id === current_id)
    } else {
      var current_id = selectedDeletingTopic.id
      var index = -2
      if (selectedDeletingTopic.d_type === "lessonplan") {
        deleteSuggestedTopic()
        return
      } else {
        index = modulesData[selectedStage][`module_` + selectedModule].topics.findIndex((res) => res.id === current_id)
      }
    }
    let value = ""
    if (hashValue !== "real") {
      value = `&draft_content=true&stage_id=${selectedStage}`
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${url}/` + current_id + "/?delete_children=true" + value, {
        headers: DefaultHeader,
        body: url === "modules" ? selectedDeletingModule : selectedDeletingTopic,
      })
      .then((res) => {
        if (url === "modules") {
          let current_modules = modules[selectedStage].modules
          current_modules.splice(index, 1)
          if (modules[selectedStage] && current_modules.length > 0) {
            setSelectedModule(null)
          } else {
            setSelectedModule(null)
          }
          triggerChanges("lbsessions")
        } else {
          let item
          if (deleteVisibleResource && deleteVisibleResource.length > 0) {
            item = deleteVisibleResource[0]
            item.id = item.resource_data.id
            item.thumbnail_url = item.resource_data.thumbnail_url
            item.name = item.resource_data?.display_name ?? item.resource_data?.name

            delete item["resource_data"]
            delete item["resource_id"]
            delete item["resource_type"]
          }
          modulesData[selectedStage][`module_` + selectedDeletingTopic.module].topics.splice(index, 1)
          if (
            modulesData[selectedStage][`module_` + selectedDeletingTopic.module].topics &&
            modulesData[selectedStage][`module_` + selectedDeletingTopic.module].topics.length > 0
          ) {
            setTopicFetched(false)
            setSelectedTopic(modulesData[selectedStage][`module_` + selectedDeletingTopic.module].topics[0].id)
          } else {
            setSelectedTopic(null)
          }
          let d_topic = selectedDeletingTopic.session_data
          if (d_topic !== null && selectedStageName === "Workshop") {
            triggerChanges("todo" + d_topic.session_type + `__` + d_topic.id, current_id, null)
          }
        }
        setSelectedDeletingModule([])
        setSelectedDeletingTopic([])
        setDeleteVisibleResource([])
        setDmodal(!dmodal)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  function toggleModule(e = null, datum_id) {
    if (e) {
      e.preventDefault()
    }
    selectedModuleToggle[datum_id] = !selectedModuleToggle[datum_id]
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }

  function fileInputClicked(event, id, module_id, data) {
    setUploadInTopic(id)
    setUploadInModule(module_id)
    setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))

    window.removeEventListener("focus", handleFocusBack)
  }

  function handleFocusBack() {
    setUploadInTopic(null)
    setUploadInModule(null)
    setUploadDataSequence(null)
    window.removeEventListener("focus", handleFocusBack)
  }

  function clickedFileInput() {
    window.addEventListener("focus", handleFocusBack)
  }

  function getImageToBeRendered(item) {
    let imgObj = turientimage,
      typeId = 2

    let typeOfFile = item?.resource_data?.file_type || 0
    if (item?.resource_data?.avatar) {
      imgObj = item.resource_data.avatar
    }

    if (item.resource_type === "video") {
      imgObj = video
    } else if (item.resource_type === "blog") {
      imgObj = blog
    } else if (item.resource_type === "link") {
      imgObj = link
    } else if (item.resource_type === "quiz") {
      imgObj = quiz
      let dataType = item?.resource_data?.data_type || 0
      if (dataType === "1") {
        imgObj = poll
      } else if (dataType === "2") {
        imgObj = popQuiz
      }
    } else if (item.resource_type === "turientimage") {
      imgObj = faImage
      typeId = 1
      if (typeOfFile === "2" || typeOfFile === "5") {
        imgObj = doc
        typeId = 2
      } else if (typeOfFile === "3" || typeOfFile === "7") {
        imgObj = excel
        typeId = 2
      } else if (typeOfFile === "4" || typeOfFile === "6") {
        imgObj = ppt
        typeId = 2
      } else if (typeOfFile === "1") {
        imgObj = pdf
        typeId = 2
      }
    } else if (item.resource_type === "checklist") {
      imgObj = checklist
    } else if (item.resource_type === "casestudy") {
      imgObj = casestudy
    } else if (item.resource_type === "matchthefollowing") {
      imgObj = whiteMatchingIcon
    }
    return { typeId, imgObj }
  }
  function showResourcesButton(type, id, visible_as_resource = false, module_id = null) {
    let data = []
    let stage_data = modulesData[selectedStage]
    if (type === `topic`) {
      var index = null
      if (
        modulesData[selectedStage][`module_` + module_id] &&
        modulesData[selectedStage][`module_` + module_id].topics &&
        modulesData[selectedStage][`module_` + module_id].topics.length > 0
      ) {
        index = modulesData[selectedStage][`module_` + module_id].topics.findIndex((res) => res.id === id)
      }
      data =
        visible_as_resource === false
          ? topicsResources[`topic_` + id]
          : index !== null && modulesData[selectedStage][`module_` + module_id].topics[index].visible_resources
          ? modulesData[selectedStage][`module_` + module_id].topics[index].visible_resources
          : topicsResources[`topic_` + id]
    } else if (type === `module`) {
      data = modulesResources[`module_` + id]
    }
    let next_url = null
    let see_more_loader = false
    if (type === "topic") {
      next_url = topicsResourcesNext[`topic_` + id]
      see_more_loader = topicsResourcesNextLoader[`topic_` + id]
    } else {
      next_url = modulesResourcesNext[`module_` + id]
    }
    if (data) {
      return (
        <Droppable
          isDropDisabled={createBreakoutSessionOpened || showCommonItemsModal || isPermitted}
          droppableId={`${module_id === null ? `` : module_id + `_`}${type}_${id}`}
          type={`resourceDraggable`}
        >
          {(provided, snapshot) => (
            <div
              id={`drop-area`}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={(e) => onDrop(e, module_id, id, data)}
              className={`bg-transparent px-4 ${type} ${type === `resource` ? `resources` : ``} ${
                type === `resource` && collapse ? `${Styles.opacity_0} p-0` : `${Styles.opacity_1}`
              }`}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {data.map((item, index) => {
                let { typeId, imgObj } = getImageToBeRendered(item)
                return (
                  <>
                    <Draggable
                      key={item.id + `_${type}_name_` + index}
                      draggableId={item.id.toString() + `-${type}` + "_" + index.toString()}
                      index={index}
                      className={item.resource_data !== null ? "" : "d-none"}
                      isDragDisabled={isPermitted}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={item.resource_data !== null ? "resource-item" : "d-none"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            className={`pr-2 d-flex`}
                            style={{
                              color: `#E0E2E5`,
                              background: "#4F6080",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="d-flex" style={{ alignItems: "center" }}>
                              <FontAwesomeIcon
                                className={`${modulesResourcesFetched} ${topicsResourcesFetched} ml-1 ${item.resource_type ? `d-none` : ``}`}
                                icon={faSpinner}
                                size="lg"
                              />
                              {item.resource_data ? (
                                item.resource_data.thumbnail_url ? (
                                  <LazyLoadImage
                                    className={`my-auto`}
                                    src={item.resource_data.thumbnail_url}
                                    alt={item.name}
                                    style={{
                                      width: `28px`,
                                      height: `20px`,
                                      fontSize: `12px`,
                                    }}
                                  />
                                ) : (
                                  <>
                                    {typeId === 1 ? (
                                      <FontAwesomeIcon
                                        icon={imgObj}
                                        className={`my-0`}
                                        style={{
                                          color: "#67edfd",
                                          marginBottom: 0,
                                          width: `28px`,
                                          height: `20px`,
                                        }}
                                      />
                                    ) : (
                                      <LazyLoadImage
                                        className={`my-auto`}
                                        src={imgObj}
                                        alt={item.name}
                                        style={{
                                          width: `28px`,
                                          height: `20px`,
                                          fontSize: `12px`,
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              ) : (
                                ``
                              )}
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: "0px 0px 0px 10px",
                                }}
                              >
                                {item.resource_data ? item.resource_data?.display_name ?? item.resource_data?.name : item.name}
                              </p>
                            </div>
                            {!isPermitted && (
                              <div>
                                <img
                                  src={DeleteIcon}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    const newState = data
                                    newState.splice(index, 1)
                                    setResourceDeleted(false)
                                    if (type === "topic") {
                                      topicsResources[`topic_` + id] = newState
                                      setTopicsResourcesFetched(false)
                                    } else {
                                      modulesResources[`module_` + id] = newState
                                      setModulesResourcesFetched(false)
                                    }
                                    deleteResource(item.id, true, item, id)
                                  }}
                                  alt={"resource delete icon"}
                                  className={`my-auto pointer`}
                                  style={{ objectFit: `contain`, height: `13px` }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </>
                )
              })}
              {provided.placeholder}
              <React.Fragment>
                {type === "module" && next_url && Object.keys(next_url).length > 0 ? (
                  <div className={`text-center`}>
                    <Button
                      variant="none"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        fetchNextModuleResources(next_url.next)
                      }}
                      className={`${Styles.font_12} border_on_hover text-center px-3 py-1 mt-2`}
                      style={{
                        backgroundColor: `#212c42`,
                        color: `#c6cff4`,
                        borderRadius: `15px`,
                      }}
                    >
                      See More
                    </Button>
                  </div>
                ) : type === "topic" && next_url && Object.keys(next_url).length > 0 ? (
                  <div className={`text-center`}>
                    <Button
                      disabled={see_more_loader}
                      variant="none"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        fetchNextTopicResources(next_url.next, id)
                      }}
                      className={`${Styles.font_12} border_on_hover text-center d-flex px-3 py-1 mt-2 mx-auto`}
                      style={{
                        backgroundColor: `#212c42`,
                        color: `#c6cff4`,
                        borderRadius: `15px`,
                      }}
                    >
                      {see_more_loader ? <Spinner animation="border" variant="secondary" size="sm" className="mr-2" /> : ""}
                      See More
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <div className={`justify-content-center pt-4 pb-3 ${type === "resource" ? `d-none` : `d-flex bg-transparent`}`}>
                  {/*<p className={`pl-3`} >
                    <span className={`${Styles.font_14}`} style={{ color: `#E0E2E5` }}>Drag and drop media/document files to add content</span>
                  </p>*/}
                  <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent text-center ${files.length === 0 ? `` : `border-0`}`}>
                    <div className={`w-100 col-12`}>
                      <Form className="my-form mb-0">
                        {!isPermitted && (
                          <div>
                            {" "}
                            {/*onClick={(e)=>{setUploadInTopic(id);setUploadInModule(module_id);setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))}} >*/}
                            <p className={`${StylesM.font_14} mb-2`}>
                              Drag and drop media/document files to add content
                              {/*/&nbsp;<label className={`button btn border-0 px-0 ${StylesM.font_14}`} style={{ color:"#E0E2E5",textDecoration:'underline'}} htmlFor="fileElem">Browse</label>*/}
                            </p>
                            {/*<p className={`${StylesM.font_14} mb-4`} >Files Supported: MP4/ PDF/ XLS/ JPG/PNG</p>*/}
                          </div>
                        )}
                        {files.length === 0 ? (
                          <input
                            className={`d-none`}
                            type="file"
                            id={"fileElem_" + id}
                            multiple
                            accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                            onClick={(e) => {
                              clickedFileInput(e)
                            }}
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              fileInputClicked(e, id, module_id, data)
                              validateAndAdd(e)
                            }}
                          />
                        ) : null}
                        {/*<label className={`button btn border-0 px-5 border_on_hover ${StylesM.font_14}`} style={{ backgroundColor: `#586886`, boxShadow:'0px 3px 6px #00000029', color:"#E0E2E5", borderRadius:'36px' }} htmlFor="fileElem">Browse</label>*/}
                      </Form>
                    </div>
                    {uploadInModule !== null &&
                    uploadInTopic !== null &&
                    uploadDataSequence !== null &&
                    uploadInModule === module_id &&
                    uploadInTopic === id ? (
                      <UploadFiles
                        files={files}
                        setFiles={(value) => setFiles(value)}
                        fetchTopicResources={(id) => fetchTopicResources(id)}
                        showSection={showSection}
                        setShowSection={setShowSection}
                        refetchLeftSideResourcesBar={refetchLeftSideResourcesBar}
                        properties={{
                          module: modules[selectedStage].modules[modules[selectedStage].modules.findIndex((res) => res.id === uploadInModule)].name,
                          topic: uploadInTopic,
                          mainId: uploadInTopic,
                          itemName:
                            stage_data[`module_` + uploadInModule].topics[
                              stage_data[`module_` + uploadInModule].topics.findIndex((res) => res.id === uploadInTopic)
                            ].name,
                          sequence: uploadDataSequence,
                          draft_content: hashValue === "real" ? `false` : `true`,
                          stage_id: selectedStage,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function openNavWithResourceSection(section, type) {
    if (!leftNavOpen) {
      setLeftNavOpen(true)
    }
    if (activeNavItem !== section) {
      setActiveNavItem(section)
    }
    if (activeResourceType !== type) {
      setActiveResourceType(type)
    }
  }

  function uploadDataProps(topic_id, module_id, data, showSection = "") {
    let seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)

    setUploadInTopic(topic_id)
    setUploadInModule(module_id)
    setUploadDataSequence(seq)
    setShowSection(showSection)

    if (addResourceType === "common") {
      setAttrForResources({
        template: topic_id,
        mainId: topic_id,
        add_to_group_resource: "true",
        draft_content: hashValue === "real" ? `false` : `true`,
        stage_id: selectedStage,
      })
    } else {
      setAttrForResources({
        topic: topic_id,
        mainId: topic_id,
        draft_content: hashValue === "real" ? `false` : `true`,
        stage_id: selectedStage,
      })
    }
  }
  function uploadDocs(e, topic_id, module_id, data, upload, showSection) {
    e.preventDefault()
    e.stopPropagation()
    uploadDataProps(topic_id, module_id, data, showSection)
    let domObj = document.getElementById("fileElem_" + topic_id)
    if (domObj && upload) {
      if (showSection === "turientimage") {
        domObj.accept = "image/*"
      }
      domObj.click()
    }
  }
  function openNewWin(section, type, mainId, module_id, data) {
    let wnd,
      seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)
    uploadDataProps(mainId, module_id, data)
    let draft_content = hashValue === "real" ? `false` : `true`
    let stage_id = selectedStage
    let parentTempId = course_template.id

    if (type === "quiz") {
      wnd = window.open("/app/quizzes/create", type + "12")
      quizWin.current = {
        wnd,
        mainId,
        module_id,
        seq,
        section,
        draft_content,
        stage_id,
        addResourceType,
        parentTempId,
      }
    } else if (type === "mtf") {
      wnd = window.open("/app/match-the-following/create", type + "12")
      mtfWin.current = {
        wnd,
        mainId,
        module_id,
        seq,
        section,
        draft_content,
        stage_id,
        addResourceType,
        parentTempId,
      }
    } else if (type === "casestudy") {
      wnd = window.open("/app/case-study/create", type + "12")
      casestudyWin.current = {
        wnd,
        mainId,
        module_id,
        seq,
        section,
        draft_content,
        stage_id,
        addResourceType,
        parentTempId,
      }
    } else if (type === "checklist") {
      wnd = window.open("/app/checklist/create", type + "12")
      checklistWin.current = {
        wnd,
        mainId,
        module_id,
        seq,
        section,
        draft_content,
        stage_id,
        addResourceType,
        parentTempId,
      }
    }
  }
  function getNewWinData(type) {
    let win = null
    if (type === "quiz") {
      win = quizWin.current
    } else if (type === "mtf") {
      win = mtfWin.current
    } else if (type === "casestudy") {
      win = casestudyWin.current
    } else if (type === "checklist") {
      win = checklistWin.current
    }
    return win
  }

  function handleNewWinMessages(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      if (event.data.instruction === "ready") {
        let win = getNewWinData(event.data.type)
        if (win && win.wnd) {
          let obj = {
            topic: win.mainId,
            sequence: win.seq,
            draft_content: win.draft_content,
            stage_id: win.stage_id,
          }
          if (win.addResourceType === "common") {
            obj = {
              template: win.mainId,
              sequence: win.seq,
              add_to_group_resource: "true",
              draft_content: win.draft_content,
              stage_id: win.stage_id,
            }
          }

          win.wnd.postMessage({ instruction: { ...obj } }, window.location.origin)
        }
      } else if (event.data.instruction === "payload") {
        let win = getNewWinData(event.data.type)
        if (win) {
          setTimeout(() => {
            if (win.addResourceType === "common") {
              fetchBreakoutGroupsResources(win.mainId, win.parentTempId, win.draft_content)
            } else {
              fetchTopicResources(win.mainId)
            }
          }, 1500) //Delay because the python backend thread takes time
          const evt = new CustomEvent("my-refresh-resources", {
            bubbles: true,
            detail: event.data.type,
          })
          document.dispatchEvent(evt)
        }
      }
    }
  }

  function openModal(type, mainId, module_id, data) {
    let seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)
    uploadDataProps(mainId, module_id, data)
    let draft_content = hashValue === "real" ? `false` : `true`
    let stage_id = selectedStage
    let parentTempId = course_template.id

    let obj = {
      topic: mainId,
      mainId,
      sequence: seq,
      draft_content,
      stage_id,
      addResourceType,
      parentTempId,
    }
    if (addResourceType === "common") {
      obj = {
        template: mainId,
        mainId,
        sequence: seq,
        add_to_group_resource: "true",
        draft_content,
        stage_id,
        addResourceType,
        parentTempId,
      }
    }

    if (type === "poll") {
      setShowPollModal(true)

      const evt = new CustomEvent("my-poll-data", {
        bubbles: true,
        detail: { ...obj },
      })
      document.dispatchEvent(evt)
    } else if (type === "pop-quiz") {
      setShowPopQuizModal(true)

      const evt = new CustomEvent("my-pop-quiz-data", {
        bubbles: true,
        detail: { ...obj },
      })
      document.dispatchEvent(evt)
    }
  }
  function handlePollModalMsgs(evt) {
    setShowPollModal(false)
    setTimeout(() => {
      if (evt.detail.addResourceType === "common") {
        fetchBreakoutGroupsResources(evt.detail.mainId, evt.detail.parentTempId, evt.detail.draft_content)
      } else {
        fetchTopicResources(evt.detail.mainId ?? uploadInTopic)
      }
    }, 1500) //Delay because the python backend thread takes time
  }

  function handlePopQuizModalMsgs(evt) {
    setShowPopQuizModal(false)
    setTimeout(() => {
      if (evt.detail.addResourceType === "common") {
        fetchBreakoutGroupsResources(evt.detail.mainId, evt.detail.parentTempId, evt.detail.draft_content)
      } else {
        fetchTopicResources(evt.detail.mainId ?? uploadInTopic)
      }
    }, 1500) //Delay because the python backend thread takes time
  }

  function showTopicsData(datum, visible_as_resource = false, module_id = null) {
    let topic_id = datum.id,
      resClassName = "",
      resStyles = {}
    if (topic_id !== null) {
      // for adding multimedia links start
      let data = []
      let stage_data = modulesData[selectedStage]
      var index = null
      if (
        modulesData[selectedStage][`module_` + module_id] &&
        modulesData[selectedStage][`module_` + module_id].topics &&
        modulesData[selectedStage][`module_` + module_id].topics.length > 0
      ) {
        index = modulesData[selectedStage][`module_` + module_id].topics.findIndex((res) => res.id === topic_id)
      }
      data =
        visible_as_resource === false
          ? topicsResources[`topic_` + topic_id]
          : index !== null && modulesData[selectedStage][`module_` + module_id].topics[index].visible_resources
          ? modulesData[selectedStage][`module_` + module_id].topics[index].visible_resources
          : topicsResources[`topic_` + topic_id]
      // for adding multimedia links end
      if (visible_as_resource) {
        return showResourcesButton(`topic`, topic_id, visible_as_resource, module_id)
      } else if (topic_id !== null && topicFetched && topicsData["topic_" + topic_id]) {
        return (
          <div
            key={"div1" + topic_id}
            style={{
              padding: "12px",
              backgroundColor: "#36435D",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            {selectedStageName === "Workshop" && course.course_type !== "1" ? (
              <div className="d-flex" style={{ paddingBottom: "12px" }}>
                <DurationInputField datum={datum} handleSave={handleSave} course={course} selectedStageName={selectedStageName} />
                <WeightageField datum={datum} handleSave={handleSave} course={course} selectedStageName={selectedStageName} />
              </div>
            ) : (
              ""
            )}
            <div
              key={"div2" + topic_id}
              className={`px-0`}
              style={{
                backgroundColor: "#42506C",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div
                style={{
                  borderRadius: "8px 8px 0px 0px",
                  borderBottom: `1px dashed #838b9e`,
                  padding: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    color: "#E0E2E5",
                    lineHeight: 1,
                    opacity: 0.6,
                  }}
                >
                  Resources
                </p>
              </div>
              {showResourcesButton(`topic`, topic_id, visible_as_resource, module_id)}
              {!isPermitted && (
                <div
                  className="d-flex p-1"
                  style={{
                    borderTop: `1px dashed #838b9e`,
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <OverlayTrigger overlay={<Tooltip>Add video</Tooltip>}>
                    {/*<img onClick={(e)=>{openNavWithResourceSection('Resource','video');uploadDocs(e,topic_id,module_id,data,false); showSection('video')}} src={multimedia} alt={`add Video button`} title={`Add Videos`} className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`} style={{ objectFit: `contain`, width: `21px`, height: `21px` }}/>*/}
                    <FontAwesomeIcon
                      icon={faVideo}
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "video")
                        uploadDocs(e, topic_id, module_id, data, false, "video")
                      }}
                      title={`Add Videos`}
                      alt={`Add Videos`}
                      className={`${activeResourceType === "video" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add blog</Tooltip>}>
                    <img
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "blog")
                        uploadDataProps(topic_id, module_id, data, "")
                        setAddBlog(true)
                      }}
                      src={WhiteBlogIcon}
                      title={`Add Blog`}
                      alt={`add blog button`}
                      className={`${activeResourceType === "blog" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add documents</Tooltip>}>
                    <img
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "document")
                        uploadDocs(e, topic_id, module_id, data, false, "document")
                      }}
                      src={documents}
                      alt={`Add Documents`}
                      title={`Add Documents(Doc, Excel, PPT, PDF, Google Docs Link)`}
                      className={`${
                        activeResourceType === "document" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add images</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faImage}
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "turientimage")
                        uploadDocs(e, topic_id, module_id, data, true, "turientimage")
                      }}
                      title={`Add Images`}
                      alt={`Add Images`}
                      className={`${
                        activeResourceType === "turientimage" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add links</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faLink}
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "link")
                        uploadDocs(e, topic_id, module_id, data, false, "link")
                      }}
                      title={`Add Links`}
                      alt={`Add Links`}
                      className={`${activeResourceType === "link" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add quiz</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        openNavWithResourceSection("Poll", "quiz")
                        openNewWin("Poll", "quiz", topic_id, module_id, data)
                      }}
                      src={WhiteQuizIcon}
                      title={`Add Quiz`}
                      alt={`add quiz button`}
                      className={`${activeResourceType === "quiz" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  {/* <OverlayTrigger overlay={<Tooltip>Add Matching</Tooltip>}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation()
                      openNavWithResourceSection("Poll", "mtf")
                      openNewWin("Poll", "mtf", topic_id, module_id, data)
                    }}
                    src={whiteMatchingIcon}
                    title={`Add Matching`}
                    alt={`add matching button`}
                    className={`${activeResourceType === "mtf" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger> */}
                  <span style={{ width: 17, height: 17, marginBottom: 12 }}>&nbsp;|&nbsp;</span>
                  {/*<img onClick={(e)=>{e.stopPropagation();openNavWithResourceSection('Resource','turientimage')}} src={WhiteTurientimageIcon} alt={`add file button`} className={`${leftNavOpen && activeResourceType === 'turientimage' ? StylesB.resource_select_icon_active : StylesB.resource_select_icon} my-auto mx-1 pointer`} style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: '#fff' }}/>*/}
                  <OverlayTrigger overlay={<Tooltip>Add poll</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        openNavWithResourceSection("Poll", "poll")
                        openModal("poll", topic_id, module_id, data)
                      }}
                      src={WhitePollIcon}
                      title={`Add Poll`}
                      alt={`add poll button`}
                      className={`${activeResourceType === "poll" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  {selectedStageName === "Workshop" ? (
                    <OverlayTrigger overlay={<Tooltip>Add pop-quiz</Tooltip>}>
                      <img
                        onClick={(e) => {
                          e.stopPropagation()
                          openNavWithResourceSection("Poll", "pop-quiz")
                          openModal("pop-quiz", topic_id, module_id, data)
                        }}
                        src={popQuiz}
                        title={`Add Pop-Quiz`}
                        alt={`add pop quiz button`}
                        className={`${
                          activeResourceType === "pop-quiz" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                        } my-auto mx-1 pointer`}
                        style={{
                          objectFit: `contain`,
                          width: `17px`,
                          height: `17px`,
                          color: "#fff",
                        }}
                      />
                    </OverlayTrigger>
                  ) : (
                    ""
                  )}
                  <OverlayTrigger overlay={<Tooltip>Add checklist</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        openNavWithResourceSection("Poll", "checklist")
                        openNewWin("Poll", "checklist", topic_id, module_id, data)
                      }}
                      src={checklist}
                      title={`Add Checklist`}
                      alt={`add checklist button`}
                      className={`${
                        activeResourceType === "checklist" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add case-study</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        openNavWithResourceSection("Poll", "casestudy")
                        openNewWin("Poll", "casestudy", topic_id, module_id, data)
                      }}
                      src={casestudy}
                      title={`Add Case-Study`}
                      alt={`add case study button`}
                      className={`${
                        activeResourceType === "casestudy" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                </div>
              )}
            </div>
            {course.course_type === "0" && selectedStageName === "Workshop" ? (
              <BreakoutSessionSection
                course={course}
                template_id={selectedTemplate}
                topic_status={datum.topic_status}
                datum={topicsData["topic_" + topic_id]}
                setCurrentSessions={(value) => {
                  changeCurrentSession(topic_id, value)
                }}
                handleSave={handleSave}
                setSelectedBreakoutForEdit={(value) => setSelectedBreakoutForEdit(value)}
                setCreateBreakoutSessionOpened={(value) => setCreateBreakoutSessionOpened(value)}
                setSelectedTopicForBreakout={(value) => setSelectedTopicForBreakout(value)}
                isPermitted={!isPermitted}
              />
            ) : (
              ""
            )}
          </div>
        )
      }
    } else return null
  }
  function showTopicsButtons(module_id) {
    let stage_data = modulesData[selectedStage]
    if (stage_data && stage_data[`module_` + module_id] && stage_data[`module_` + module_id].topics) {
      let module_datum = stage_data[`module_` + module_id]
      if (stage_data[`module_` + module_id].topics.length >= 0) {
        return (
          <Droppable isDropDisabled={createBreakoutSessionOpened || showCommonItemsModal} droppableId={`module_` + module_id} type={`topicDraggable`}>
            {(provided, snapshot) => (
              <React.Fragment>
                <div className={`bg-transparent`} ref={provided.innerRef} style={getTopicListStyle(snapshot.isDraggingOver)}>
                  {stage_data[`module_` + module_id].topics.length > 0 ? (
                    <>
                      {stage_data[`module_` + module_id].topics.map((datum, index) => {
                        return (
                          <Draggable
                            key={datum.id + `_topic_name_` + index}
                            draggableId={datum.id.toString() + "-topic" + "_" + index.toString()}
                            isDragDisabled={
                              (datum.session_data && datum.session_data.session_type && datum.session_data.session_type === "2") ||
                              (datum.topic_status && datum.topic_status === "Done") ||
                              isPermitted
                            }
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                key={datum.id + `_topic_name_` + index + snapshot.isDragging}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`border_on_hover bg-transparent float-none ${
                                  datum.topic_status && datum.topic_status === "Done" ? Styles.opacity_0_5 : Styles.opacity_1
                                }`}
                                style={getTopicItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                {showDroppableData(datum, "topic", module_id, index)}
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                    </>
                  ) : (
                    <div className="text-center py-3 justify-content-center">
                      <p
                        style={{
                          fontSize: "13px",
                          margin: "0px",
                          color: "#E0E2E5",
                          marginBottom: "5px",
                        }}
                      >
                        No topics created for this {selectedStageName === "Workshop" && course.course_type !== "1" ? "session" : "module"}!
                      </p>
                    </div>
                  )}

                  {provided.placeholder}
                </div>
                {stage_data[`module_` + module_id].topics.length > 0 ? (
                  showDroppableSubData(stage_data, "topic", module_id)
                ) : (
                  <div className="d-flex justify-content-center">{showDroppableSubData(stage_data, "topic", module_id)}</div>
                )}
              </React.Fragment>
            )}
          </Droppable>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function showCurrentModuleData(module_id) {
    if (modulesData[selectedStage] && modulesData[selectedStage][`module_` + module_id] !== undefined) {
      return (
        <Card className={`${StylesB.topic_card} w-100`}>
          <Card.Body className="p-12px">{showTopicsButtons(module_id)}</Card.Body>
        </Card>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function showCourseDays(datum) {
    if (courseDays > 0) {
      let days_divs = []
      for (var i = 1; i <= courseDays; i++) {
        let current_day = i
        days_divs.push(
          <p
            key={"day_" + i.toString() + "_" + datum.id}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleSave("course_day", current_day, datum, "module")
            }}
            className={`${current_day === datum.course_day ? StylesB.module_selected_day : ""} border_on_hover`}
            style={{
              boxShadow: "0px 3px 6px #00000029",
              opacity: 0.5,
              marginBottom: "0px",
              marginRight: "6px",
              marginLeft: "6px",
              fontSize: "13px",
              padding: "5px 10px",
              minWidth: "63px",
              textAlign: "center",
              borderRadius: "3px",
              backgroundColor: "#586886",
              height: "30px",
              color: "#D3D5E2",
            }}
          >
            <span>Day {i}</span>
          </p>
        )
      }
      return days_divs
    } else {
      return null
    }
  }

  function showDroppableData(datum, type, module_id = null, index = null) {
    if (type === "module") {
      return (
        <React.Fragment>
          <div
            id={"modules_" + datum.id}
            className={`${Styles.font_16} ${selectedModuleToggle[datum.id]} pr-2 shadow-none d-flex`}
            style={{
              color: `#F2F4FF`,
              justifyContent: "space-between",
              backgroundColor: datum.module_type === "5" ? "#2d384c" : "#42506C",
              borderRadius: datum.module_type === "5" ? "8px" : "8px 8px 0px 0px",
              position: `relative`,
            }}
          >
            <Col xs="11" sm="11" md="11" lg="11" className="d-flex pl-0">
              {datum.session_type && datum.session_type === "1" ? <div className={`${Styles.online_green_badge}`} /> : ``}
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setSelectedModule(datum.id)
                  toggleModule(e, datum.id)
                }}
                className={`d-inline-flex pl-2 pr-3 py-1 align-items-center`}
                style={{
                  backgroundColor: "#38445C",
                  borderRadius: "9px 0px 0px 0px",
                  borderRight: "1px solid #FFD80035",
                }}
              >
                <img
                  src={DragHandleIcon}
                  alt={`drag-handle`}
                  className={`my-auto mr-1 pointer`}
                  style={{
                    objectFit: `contain`,
                    height: `13px`,
                    width: "16px",
                  }}
                />
                <p
                  className={`mb-0 pl-2`}
                  style={{
                    fontSize: "19px",
                    fontWeight: "500",
                    color: "#FFFFFF70",
                  }}
                >
                  {module_id + 1}
                </p>
              </div>
              <NameInputField
                datum={datum}
                handleSave={handleSave}
                moduleEdited={moduleEdited}
                course={course}
                selectedStageName={selectedStageName}
                type="module"
                disabled={datum.session_type && datum.session_type === "2"}
              />
              {/*{currentInputField === 'modules_'+datum.id || datum.module_type === '5' ?
                <input autoComplete="off" type="text" name="name" id={datum.id+`_module_input_name`} onBlur={(e) => {e.preventDefault();e.stopPropagation();setCurrentInputField('')}} defaultValue={datum.name} onChange={(e) => {e.preventDefault();handleSave(e,e.target.name,e.target.value,datum,'module');}} autoFocus placeholder={"Enter Name"} className={`${StylesB.session_input_field} form-control px-2 font-weight-bold ${Styles.font_16} d-inline-flex w-100`} required />
                :
                <p onClick={(e) => {e.preventDefault();e.stopPropagation();setCurrentInputField('modules_'+datum.id)}} className={`${StylesB.activity_input_field} d-inline-flex w-100 align-items-center font-weight-bold form-control border-0 px-2 my-auto ${Styles.font_16}`} style={{minHeight: `38px`,cursor:'text'}} >{showModuleName(datum)}</p>
              }*/}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip placement="bottom" className={`classroom-tooltip `} id={`"tooltip-${datum.id}"`}>
                    <div>
                      {datum.description
                        ? "View Description"
                        : selectedStageName === "Workshop" && course.course_type !== "1"
                        ? "Add Session Objective"
                        : "Add Module Description"}
                    </div>
                  </Tooltip>
                }
              >
                <div className={`mx-2 pointer`}>
                  <img
                    src={datum.description ? AddedDescriptionIcon : AddDescriptionIcon}
                    alt={`add-description`}
                    onClick={() => {
                      setShowAddDescriptionModal(true)
                      setActiveDatum(datum)
                    }}
                    className={`my-auto pointer`}
                    style={{
                      objectFit: `contain`,
                      height: `auto`,
                      width: "auto",
                    }}
                  />
                </div>
              </OverlayTrigger>
              {datum.module_type === "5" ? (
                <SessionTimingField datum={datum} handleSave={handleSave} course={course} selectedStageName={selectedStageName} />
              ) : (
                ""
              )}
            </Col>
            <div className="d-flex px-0 col-1 justify-content-end">
              {datum.module_type !== "5" ? (
                <Button
                  variant="transparent"
                  id={`${selectedModuleToggle[datum.id] ? `disable` : ``}click_module${datum.id}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSelectedModule(datum.id)
                    toggleModule(e, datum.id)
                  }}
                  className={`p-0 m-0 border-0 shadow-none text-white`}
                >
                  <FontAwesomeIcon
                    className="mr-2 pointer"
                    icon={selectedModuleToggle[datum.id] ? faChevronCircleUp : faChevronCircleDown}
                    style={{
                      width: `16px`,
                      height: `16px`,
                      margin: `auto 0px`,
                    }}
                  />
                </Button>
              ) : (
                ""
              )}
              {!isPermitted && datum.session_type && datum.session_type === "0" ? (
                <img
                  src={DeleteIcon}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setDmodal(true)
                    setSelectedDeletingModule(datum)
                  }}
                  alt={`delete icon`}
                  className={`my-auto mr-1 pointer`}
                  style={{ objectFit: `contain`, height: `13px` }}
                />
              ) : (
                ``
              )}
            </div>
          </div>
          {selectedModuleToggle[datum.id] ? (
            <Col xs="12" sm="12" md="12" style={{ padding: "0px" }}>
              <div style={{ padding: "12px", borderBottom: "1px dashed #404a5d" }}>
                {/* <DescriptionInputField datum={datum} handleSave={handleSave} moduleEdited={moduleEdited} course={course} selectedStageName={selectedStageName}  /> */}
                {selectedStageName === "Workshop" && course.course_type !== "1" ? (
                  <div
                    className="d-flex py-1"
                    style={{
                      marginTop: "12px",
                      backgroundColor: "#42506C",
                      borderRadius: "8px",
                    }}
                  >
                    <SessionTimingField datum={datum} handleSave={handleSave} course={course} selectedStageName={selectedStageName} />
                    {course.course_type === "0" ? (
                      <div
                        className="d-flex align-items-center hide_scrollbar"
                        style={{
                          width: "calc(100% - 300px)",
                          overflowX: "auto",
                        }}
                      >
                        {showCourseDays(datum)}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {showCurrentModuleData(datum.id)}
            </Col>
          ) : (
            <React.Fragment>
              {datum.module_type !== "5" ? (
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  style={{
                    padding: "9px",
                    backgroundColor: "#303C54",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <p
                    style={{ marginBottom: "0px", fontSize: "13px" }}
                    className="pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setSelectedModule(datum.id)
                      toggleModule(e, datum.id)
                    }}
                  >
                    <u>
                      {datum.topic_count} {course.course_type === "3" && selectedStageName === "Workshop" ? "Lesson" : "Topic"}
                      {datum.topic_count > 1 ? "s" : ""} added
                    </u>
                  </p>
                </Col>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    } else if (type === "topic") {
      let modouleindex = modules[selectedStage].modules.findIndex((res) => res.id === module_id)
      return (
        <React.Fragment>
          <div
            id={"topics_" + datum.id}
            className={`${Styles.font_16} px-0 shadow-none d-flex col-12`}
            style={{
              color: `#F2F4FF`,
              justifyContent: "space-between",
              backgroundColor: "#42506C",
              borderRadius: selectedTopicToggle[datum.id] ? "8px 8px 0px 0px" : "8px",
            }}
          >
            <Col xs="6" sm="6" md="6" lg="6" xl="7" className="d-flex px-0 align-items-center">
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setSelectedTopic(datum.id)
                  toggleTopic(e, datum.id)
                }}
                className={`d-inline-flex px-2 py-1 align-items-center`}
                style={{
                  backgroundColor: "#38445C",
                  borderRadius: selectedTopicToggle[datum.id] ? "9px 0px 0px 0px" : "9px 0px 0px 9px",
                  borderRight: "1px solid #FFD80035",
                  borderBottom: selectedTopicToggle[datum.id] ? "1px solid #FFD80035" : "",
                }}
              >
                <img
                  src={DragHandleIcon}
                  alt={`topic-drag-handle`}
                  className={`my-auto mr-2 pointer`}
                  style={{
                    objectFit: `contain`,
                    height: `13px`,
                    width: "16px",
                  }}
                />
                <p
                  className={`mb-0`}
                  style={{
                    fontSize: "19px",
                    fontWeight: "500",
                    color: "#FFFFFF70",
                  }}
                >{`${modouleindex + 1}.${index + 1}`}</p>
              </div>
              <div style={{ overflow: "hidden", flexGrow: 1 }}>
                <NameInputField
                  datum={datum}
                  handleSave={handleSave}
                  moduleEdited={moduleEdited}
                  course={course}
                  selectedStageName={selectedStageName}
                  type="topic"
                  disabled={
                    (datum.session_data && datum.session_data.session_type && datum.session_data.session_type === "2") ||
                    (datum.topic_status && datum.topic_status === "Done")
                  }
                />
              </div>
            </Col>
            <div className="d-flex px-1 justify-content-between align-items-center">
              <div>
                {selectedStageName === "Workshop" && isMuralEnabled ? (
                  <ActivityRoomWokSpace
                    mural_workspace={course_template?.activity_workspace}
                    activity_workspace={datum?.id ? datum : {}}
                    setModulesData={setModulesData}
                    modouleindex={modouleindex}
                    modulesData={modulesData}
                    type={"topic"}
                    selectedStage={selectedStage}
                    module_id={module_id}
                    topicIndex={index}
                    mural_account={course_template?.mural_account}
                    isPermitted={!isPermitted}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-end align-items-center" style={{ width: "15%" }}>
                <FontAwesomeIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSelectedTopic(datum.id)
                    toggleTopic(e, datum.id)
                  }}
                  className="mr-2 pointer"
                  icon={selectedTopicToggle[datum.id] ? faChevronCircleUp : faChevronCircleDown}
                  style={{ width: `16px`, height: `16px`, margin: `auto 0px` }}
                />
                {!isPermitted && (
                  <img
                    src={DeleteIcon}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setSelectedModule(module_id)
                      setDmodal(true)
                      datum.module = module_id
                      setSelectedDeletingTopic(datum)
                    }}
                    alt={`topic-delete`}
                    className={`my-auto mr-1 pointer`}
                    style={{ objectFit: `contain`, height: `13px` }}
                  />
                )}
              </div>
            </div>
          </div>
          {selectedTopicToggle[datum.id] ? showTopicsData(datum, false, module_id) : ``}
        </React.Fragment>
      )
    }
  }

  function gotoNextSection() {
    let stages = course_template.stages
    if (stages[stages.length - 1].id === selectedStage) {
      navigate(`/main/class-dashboard/${selectedTemplate}/${course.id}/`)
    } else {
      let current_stage = stages.filter((stage) => stage.id === selectedStage)[0]
      let stage_index = stages.indexOf(current_stage)
      let next_stage = stages[stage_index + 1]
      setSelectedStage(next_stage.id)
      setSelectedStageName(next_stage.name)
    }
  }

  function showDroppableSubData(stage_data, type, module_id = null) {
    if (type === "module") {
      return (
        <React.Fragment>
          {stage_data.next_url !== null && stage_data.next_loader ? (
            ""
          ) : (
            <div
              className="d-flex col-12"
              style={{
                padding: "12px",
                position: "absolute",
                bottom: "0px",
                boxShadow: "0px -3px 12px #00000099",
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#303C54",
              }}
            >
              <React.Fragment>
                <div className="d-flex">
                  {/* <p className={`mb-0 text-white pointer`} onClick={() => {setFetchSuggestedTopicsData(true);setShowSuggestedTopicsModal(true)}} style={{fontSize:"16px",fontWeight:'500'}}><u>Suggested Topics</u></p> */}
                </div>
                <div className="d-flex pr-3" style={{ alignItems: "center" }}>
                  <Button
                    disabled={!moduleCreated && showSuggestDayModal}
                    className={`${StylesB.add_button_large} ${isPermitted && "disable-content"} py-1 border_on_hover d-flex`}
                    variant="none"
                    onClick={(e) => {
                      e.stopPropagation()
                      setModuleCreated(false)
                      createModule(e)
                    }}
                  >
                    {moduleCreated || !showSuggestDayModal ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                    Add {selectedStageName === "Workshop" && course.course_type !== "1" ? "Session" : "Module"}
                  </Button>
                  {/* {selectedStageName === "Workshop" && course.course_type === "0" ? (
                    <Button
                      disabled={!moduleCreated && showSuggestDayModal}
                      className={`${StylesB.add_button_large} ${isPermitted && "disable-content"} py-1 border_on_hover d-flex align-items-center ml-2`}
                      variant="none"
                      onClick={(e) => {
                        e.stopPropagation()
                        setModuleCreated(false)
                        createModule(e, "5")
                      }}
                    >
                      {moduleCreated || !showSuggestDayModal ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                      <FontAwesomeIcon className={`mr-2 my-0`} icon={faMugHot} size="lg" />
                      <span>Add a break</span>&nbsp;
                    </Button>
                  ) : (
                    ""
                  )} */}
                  <Button
                    className={`${StylesB.add_button_large} d-flex py-1 border_on_hover  ml-2 px-3`}
                    variant="none"
                    onClick={(e) => {
                      e.stopPropagation()
                      gotoNextSection(stage_data)
                    }}
                  >
                    &emsp;Next&emsp;
                  </Button>
                  {props?.isPermitted && (
                    <>
                      <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 ml-2 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                      <p
                        style={{
                          fontSize: "12px",
                          margin: "0px",
                          color: "#E0E2E5",
                        }}
                      >
                        All changes are saved
                      </p>
                    </>
                  )}
                </div>
              </React.Fragment>
            </div>
          )}
        </React.Fragment>
      )
    } else if (type === "topic") {
      let module_datum = stage_data[`module_` + module_id]
      return (
        <div className="d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
          {currentModuleTopicsFetched && stage_data[`module_` + module_id] && stage_data[`module_` + module_id].topics_next === null ? (
            <Button
              disabled={!topicCreated}
              className={`${StylesB.add_button} ${isPermitted && "disable-content"} border_on_hover d-flex`}
              variant="none"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setTopicCreated(false)
                setSelectedModule(module_datum.id)
                selectedModuleToggle[module_datum.id] = true
                createTopic(e, module_datum.id)
              }}
            >
              {topicCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
              Add {course.course_type === "3" && selectedStageName === "Workshop" ? "Lesson" : "Topic"}
            </Button>
          ) : (
            ``
          )}
        </div>
      )
    }
  }
  function showModulesButtons() {
    let stage_data = modules[selectedStage]
    if (stage_data.modules.length > 0) {
      return (
        <Droppable droppableId={`moduleDraggable`} type={`moduleDraggable`}>
          {(provided, snapshot) => (
            <React.Fragment>
              <div
                className="px-0 d-inline-flex align-items-center w-100"
                style={{
                  marginBottom: "12px",
                  minHeight: showSuggestedTopicsModal ? "230px" : ``,
                }}
              >
                <Col lg="5" md="5" sm="5" xs="5" className={`pr-0 pl-1 mt-2 mb-auto h-100 ${showSuggestedTopicsModal ? `` : `d-none`}`}>
                  <div
                    className="mr-1 h-100 mt-0 mb-4"
                    style={{
                      backgroundColor: "#303C54",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "9px",
                      border: "1px solid #303C54",
                    }}
                  >
                    <div className={`w-100 pt-2 d-inline-flex`} style={{ borderBottom: "1px solid #FFFFFF60" }}>
                      <div className={`float-left pl-3`}>
                        <p
                          className={`mb-0 pointer`}
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#E0E2E5",
                          }}
                        >
                          Suggested Topics
                        </p>
                        <p className={`mb-0 pointer pb-2`} style={{ fontSize: "14px", color: "#E0E2E580" }}>
                          You can drag and drop these topics to your lesson plan to add a new topic
                        </p>
                      </div>
                      <div className={`float-right pl-3 pr-2`}>
                        <img
                          src={CancelIcon}
                          className="mb-0 pointer mr-2"
                          style={{ fontSize: "20px", width: "20px" }}
                          onClick={() => {
                            setShowSuggestedTopicsModal(false)
                          }}
                        />
                      </div>
                    </div>
                    <div className={`w-100 py-2 align-items-center`}>
                      <div className={`pb-3`} style={{ maxHeight: "450px", overflowY: "scroll" }}>
                        {lessonPlansFetched ? (
                          <Col xs="12" sm="12" md="12" className={`p-0`} style={{ backgroundColor: "#303C54" }}>
                            <Card
                              className={`w-100 px-2 pt-1`}
                              style={{
                                backgroundColor: "#303C54",
                                border: "1px solid #303C54",
                              }}
                            >
                              <Card.Body className={`p-0`}>{showLessonTopicsButtons()}</Card.Body>
                            </Card>
                          </Col>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={showSuggestedTopicsModal ? `7` : `12`}
                  md={showSuggestedTopicsModal ? `7` : `12`}
                  sm={showSuggestedTopicsModal ? `7` : `12`}
                  xs={showSuggestedTopicsModal ? `7` : `12`}
                  className={`px-0 mb-auto`}
                >
                  <div className="ml-1 mt-0" style={{}}>
                    <div
                      id="scrollable-div"
                      className={`${showOnlyWorkshop ? StylesB.border_radius_8 : ""} col-12 pb-5`}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {stage_data.modules.map((datum, index) => (
                        <Draggable
                          key={datum.id + `_module_name_` + index}
                          draggableId={datum.id.toString() + "-module" + "_" + index.toString()}
                          isDragDisabled={(datum.session_type && datum.session_type === "2") || isPermitted}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`${datum.session_type && datum.session_type === "2" ? Styles.opacity_0_5 : Styles.opacity_1} ${`border_on_hover`}`}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              {showDroppableData(datum, "module", index)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  </div>
                </Col>
              </div>
              {showDroppableSubData(stage_data, "module")}
            </React.Fragment>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
          <p style={{ fontSize: "14px", margin: "0px", color: "#E0E2E5" }}>
            No {selectedStageName === "Workshop" && course.course_type !== "1" ? "sessions" : "modules"} created yet!
          </p>
          <div className="d-flex justify-content-center pt-2">
            <Button
              disabled={!moduleCreated}
              className={`${StylesB.add_button_large} text-color-1 border_on_hover d-flex`}
              variant="none"
              onClick={(e) => {
                e.stopPropagation()
                setModuleCreated(false)
                createModule(e)
              }}
            >
              {moduleCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
              Add {selectedStageName === "Workshop" && course.course_type !== "1" ? "Session" : "Module"}
            </Button>
          </div>
        </Col>
      )
    }
  }
  function displayWorkshopInfo() {
    if (course.course_type === "0") {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="d-flex py-3">
          <Col lg="6" md="6" sm="6" xs="6">
            <div style={{ color: "#E0E2E5", fontSize: "14px" }}>
              <p style={{ opacity: 0.5, fontSize: "15px" }}>Before you set up the course content for live classroom</p>
              <p style={{ fontSize: "17px", marginBottom: "5px" }}>Sessions & Topics</p>
              <p style={{ marginBottom: "8px" }}>
                Your entire class will be divided into Sessions. A session is a finite period dedicated to a particular subject. The length and number of
                sessions can be defined.
              </p>
              <p style={{}}>
                Each session will be divided into topics. The number of topics in a particular session can be defined depending on the session length.
              </p>
            </div>
            <Button
              className={`border_on_hover mb-0`}
              onClick={(e) => {
                e.stopPropagation()
                setShowWorkshopInfo(false)
                setModuleCreated(false)
                createModule(null)
              }}
              style={{
                backgroundColor: "#303c54",
                padding: "7px 16px",
                borderRadius: "18px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                fontSize: "14px",
                border: "1px solid transparent",
              }}
            >
              Set Up Live Worskhop&nbsp;{" "}
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                style={{
                  height: 14,
                  width: 14,
                  borderRadius: "50%",
                  backgroundColor: "#E0E2E5",
                  color: "#212c42",
                  padding: "2px 2px 2px 3px",
                }}
              />
            </Button>
          </Col>
          <Col lg="6" md="6" sm="6" xs="6">
            <img src={WorkshopInfo} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
          </Col>
        </Col>
      )
    } else if (course.course_type === "3") {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="d-flex p-5">
          <Col lg="12" md="12" sm="12" xs="12">
            <div style={{ color: "#E0E2E5", fontSize: "14px" }}>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                3 Sessions
              </p>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>scheduled every 16th day of the month for 3 months</p>
              <p style={{ opacity: 0.5, fontSize: "15px", marginBottom: "10px" }}>Before you set up the course content for Cohort Sessions</p>
              <p style={{ fontSize: "17px", marginBottom: "5px" }}>Sessions & Lessons</p>
              <p style={{ marginBottom: "8px" }}>
                Your entire class will be divided in to Sessions. A session is a finite period of time dedicated to a particular subject. You can define the
                length of a session and thus the no of session in a particular class
              </p>
              <p style={{}}>
                Each session will be divided in to lessons. You can define any no of lessons in a particular topic depending on the session Length.
              </p>
            </div>
            <Button
              className={`border_on_hover mb-0`}
              onClick={(e) => {
                e.stopPropagation()
                setShowWorkshopInfo(false)
                setModuleCreated(false)
                createModule(null)
              }}
              style={{
                backgroundColor: "#303c54",
                padding: "7px 16px",
                borderRadius: "18px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                fontSize: "14px",
                border: "1px solid transparent",
              }}
            >
              Set Up Cohort Sessions&nbsp;{" "}
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                style={{
                  height: 14,
                  width: 14,
                  borderRadius: "50%",
                  backgroundColor: "#E0E2E5",
                  color: "#212c42",
                  padding: "2px 2px 2px 3px",
                }}
              />
            </Button>
          </Col>
        </Col>
      )
    } else return null
  }
  function showStageContent() {
    if (selectedStage) {
      let stage_data = modules[selectedStage]
      if (stage_data !== undefined && stage_data.modules !== undefined && stage_data.loader !== undefined) {
        if (
          selectedStageName === "Workshop" &&
          stage_data.modules.length === 0 &&
          showWorkshopInfo &&
          (course.course_type === "0" || course.course_type === "3")
        ) {
          return displayWorkshopInfo()
        } else {
          return (
            <React.Fragment>
              <p
                className={`mb-0 pt-2 pl-3 text-white ${isPermitted ? "disable-content" : "pointer"} ${showSuggestedTopicsModal ? `d-none` : ``}`}
                onClick={() => {
                  setFetchSuggestedTopicsData(true)
                  setShowSuggestedTopicsModal(true)
                }}
                style={{ fontSize: "18px", fontWeight: "500" }}
              >
                <u>Suggested Topics</u>
              </p>
              {showModulesButtons()}
            </React.Fragment>
          )
        }
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <Spinner animation="border" variant="secondary" size="sm" />
          </Col>
        )
      }
    } else {
      return null
    }
  }
  function showCourseStages() {
    if (course_template && course_template.stages) {
      return course_template.stages.map((node, index) => {
        return (
          <div
            key={index.toString() + node.name}
            onClick={(e) => {
              e.stopPropagation()
              setSelectedStage(node.id)
              setSelectedStageName(node.name)
            }}
            className={`${selectedStage === node.id ? StylesB.course_nav_item_active : ""} ${StylesB.course_nav_item} px-3 py-2 fs-15px pointer`}
          >
            {node.name}
          </div>
        )
      })
    } else return null
  }
  function showCourseContent() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="px-0"
        style={{
          backgroundColor: "#212C42",
          borderRadius: "8px",
          boxShadow: "0px 3px 12px #00000099",
        }}
      >
        {showOnlyWorkshop ? (
          ""
        ) : (
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="d-flex align-items-center px-0"
            style={{
              borderBottom: "1px solid #838B9E",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            {showCourseStages()}
            {selectedStageName === "Workshop" && isMuralEnabled ? (
              <WorkSpaceRoom
                activity_workspace={course_template?.id ? course_template : {}}
                setCourseTemplate={setCourseTemplate}
                sourceWorkspace="training-content"
                mural_account={course_template?.mural_account}
                isPermitted={isPermitted}
              />
            ) : (
              ""
            )}
            {breakoutGroupsDataFetched && selectedStageName === "Workshop" ? (
              <div className={`pr-3 pl-3 ${!isMuralEnabled && "ml-auto"}`} style={{ color: "#E0E2E5" }}>
                <p
                  onClick={() => {
                    setShowCreatingGroupsModal(true)
                  }}
                  className={`mb-0 d-inline-flex align-items-center pointer `}
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  {" "}
                  <FontAwesomeIcon color="#E0E2E5" className={`mb-0 mr-1`} icon={faUsers} />
                  <u>{breakoutGroupList.length > 0 ? breakoutGroupList.length : `Create`} Groups</u>
                </p>
              </div>
            ) : null}
          </Col>
        )}
        {showStageContent()}
      </Col>
    )
  }

  function showMainSection() {
    if (course_template) {
      if (course_template.stages.length > 0) {
        return (
          <Col className="px-0" lg="12" md="12" sm="12" xs="12">
            {showCourseContent()}
          </Col>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function onDragEnd(result) {
    if (createBreakoutSessionOpened) {
      setDragEnded(true)
      setDraggedItem(result)
    } else if (showCommonItemsModal) {
      onCommonResourceDragEnd(result)
    } else {
      onTrainigContentDragEnd(result)
    }
  }

  function onCommonResourceDragEnd(result) {
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    const preventedResourceType = ["mtf", "quiz", "poll", "checklist", "casestudy", "pop-quiz"].includes(currentSelectedResourcesType)
    // To prevent adding MTF,Quiz,Poll,Checklist,CaseStudy,Pop-Quiz to common resources.
    if (!result.destination || preventedResourceType) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    const sourceParentId = result.source.droppableId
    const destParentId = result.destination.droppableId
    if (type === "resource_resourceDraggable") {
      const itemSubItemMap = allResources
      var sourceSubItems, destSubItems, topic_id, module_id

      if (sourceParentId.indexOf("resource_") > -1) {
        sourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
      }

      if (destParentId.indexOf("_groupresource") > -1) {
        let datum = destParentId.split("_")
        let resource_id = datum[0]
        destSubItems = [...breakoutGroupResources]
      }

      /* In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(allResources[sourceParentId.replace("resource_", "")], sourceIndex, destIndex)
        allResources[sourceParentId.replace("resource_", "")] = items
        setAllResourcesChanged(!allResourcesChanged)
      } else {
        var newSourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        var newDestSubItems
        var formData = new FormData()

        newDestSubItems = destSubItems
        setTopicsResourcesFetched(false)

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem["sequence"] = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem["sequence"] = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/
        if (hashValue !== "real") {
          formData.append("draft_content", "true")
        }
        formData.append(`template`, selectedTemplate)
        formData.append("sequence", draggedItem.sequence)
        let sourcePIdType = sourceParentId.replace("resource_", "")
        if (sourcePIdType === "poll" || sourcePIdType === "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else {
          if (sourcePIdType === "document") {
            formData.append("resource_type", "turientimage")
          } else {
            formData.append("resource_type", sourcePIdType)
          }
        }
        formData.append("resource_id", draggedItem.id)
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/common-group-resource/", formData, { headers: login_credentials })
          .then((res) => {
            if (res.data) {
              let dummy = [...breakoutGroupResources]
              dummy.splice(result.destination.index, 0, res.data)
              setBreakoutGroupResources(dummy)
            }
            setTopicsResourcesFetched(true)
          })
          .catch((error) => {
            showErrorMessage(error)
          })
        /*api end*/
      }
    }
  }

  function onTrainigContentDragEnd(result) {
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    // To prevent adding MTF to topic for now.
    if (!result.destination || currentSelectedResourcesType === "mtf") {
      return
    }
    let stage_data = modulesData[selectedStage]
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    const sourceParentId = result.source.droppableId
    const destParentId = result.destination.droppableId
    if (result.type === "moduleDraggable") {
      let datum = modules[selectedStage].modules
      const items = reorder(datum, sourceIndex, destIndex)
      let currentModule = items[result.destination.index]
      if (result.destination.index === 0) {
        currentModule.sequence = (parseFloat(datum[0].sequence) - 10).toFixed(20)
      } else if (result.destination.index === datum.length - 1) {
        currentModule.sequence = (parseFloat(datum[datum.length - 1].sequence) + 10).toFixed(20)
      } else {
        if (result.source.index > result.destination.index) {
          currentModule.sequence = (
            (parseFloat(datum[result.destination.index - 1].sequence) + parseFloat(datum[result.destination.index].sequence)) /
            2
          ).toFixed(20)
        } else if (result.source.index < result.destination.index) {
          currentModule.sequence = (
            (parseFloat(datum[result.destination.index].sequence) + parseFloat(datum[result.destination.index + 1].sequence)) /
            2
          ).toFixed(20)
        }
      }
      //save the module sequence in api
      handleSave("sequence", currentModule.sequence, currentModule, "module")
      modules[selectedStage].modules = items
    } else if (result.type === "topicDraggable") {
      if (destParentId === sourceParentId) {
        let datum = []
        if (sourceParentId.indexOf("lessonPlan") > -1) {
          return
        } else {
          datum = stage_data[sourceParentId].topics
        }
        const items = reorder(datum, sourceIndex, destIndex)
        let currentTopic = items[result.destination.index]
        if (result.destination.index === 0) {
          currentTopic.sequence = (parseFloat(datum[0].sequence) - 10).toFixed(20)
        } else if (result.destination.index === datum.length - 1) {
          currentTopic.sequence = (parseFloat(datum[datum.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(datum[result.destination.index - 1].sequence) + parseFloat(datum[result.destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (result.source.index < result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(datum[result.destination.index].sequence) + parseFloat(datum[result.destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        //save the topic sequence in api
        handleSave("sequence", currentTopic.sequence, currentTopic, "topic")
        if (sourceParentId.indexOf("lessonPlan") > -1) {
          lessonPlanTopics[`module_` + selectedTemplate].topics = items
        } else {
          modulesData[selectedStage][sourceParentId].topics = items
        }
      } else {
        let sourceSubItems,
          destSubItems = []
        let newItems = []
        let source,
          destination = ""
        let suggested_topic

        if (sourceParentId.indexOf("lessonPlan") > -1 && !(destParentId.indexOf("lessonPlan") > -1)) {
          source = "lesson_plan"
          destination = "session_plan"
          sourceSubItems = lessonPlanTopics[`module_` + selectedTemplate].topics
          destSubItems = stage_data[destParentId].topics
          newItems = stage_data[destParentId]
        } else if (destParentId.indexOf("lessonPlan") > -1 && !(sourceParentId.indexOf("lessonPlan") > -1)) {
          return
        } else {
          source = "session_plan"
          destination = "session_plan"
          sourceSubItems = stage_data[sourceParentId].topics
          destSubItems = stage_data[destParentId].topics
          newItems = stage_data[destParentId]
        }

        let newSourceSubItems = sourceSubItems
        let [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
        if (source === "lesson_plan") {
          suggested_topic = draggedItem
          draggedItem = draggedItem.topic
        }

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)

        if (result.destination.index === 0 && destSubItems.length === 1) {
          draggedItem.sequence = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index + 1 === destSubItems.length) {
          draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem.sequence = (
            (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        let module_id = destParentId.split("_")[1].toString().replace("lessonPlan", "")
        let delete_topic = false
        if (destination === "lesson_plan") {
          delete_topic = true
        } else if (source === destination && source === "session_plan") {
          delete_topic = "add_to_do"
          draggedItem.previos_module = result.source.droppableId.split("_")[1].toString()
        } else if (source === "lesson_plan" && destination === "session_plan") {
          // Do nothing.
        }
        if (source === "lesson_plan") {
          assignSuggestedTopic(suggested_topic.id, draggedItem.sequence, module_id)
        } else {
          draggedItem.module = module_id
          // change topic and sequence using api
          handleSave("sequence", draggedItem.sequence, draggedItem, "topic", delete_topic)
        }

        if (sourceParentId.indexOf("lessonPlan") > -1 && !(destParentId.indexOf("lessonPlan") > -1)) {
          // Do nothing
        } else if (destParentId.indexOf("lessonPlan") > -1 && !(sourceParentId.indexOf("lessonPlan") > -1)) {
          let lesson_topics = { ...lessonPlanTopics }
          lesson_topics[`module_` + selectedTemplate] = newSourceSubItems
        } else {
          draggedItem.requested_module = module_id
          let modules_data = { ...modulesData }
          modulesData[selectedStage][destParentId].topics = newDestSubItems
          setModulesData(modules_data)
        }
      }
    } else if (type === "resource_resourceDraggable") {
      const itemSubItemMap = allResources
      var sourceSubItems, destSubItems, topic_id

      if (sourceParentId.indexOf("resource_") > -1) {
        sourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
      }
      if (destParentId.indexOf("resource_") > -1) {
        destSubItems = itemSubItemMap[destParentId.replace("resource_", "")]
      } else if (destParentId.indexOf("topic_") > -1) {
        let datum = destParentId.split("_")
        // module_id = datum[0]
        topic_id = datum[2]
        // destSubItems = stage_data[`module_`+module_id].topics[stage_data[`module_`+module_id].topics.findIndex(res=> res.id === parseInt(topic_id))]
        destSubItems = topicsResources[`topic_` + topic_id]
      }

      // let newItems = allResources[props.currentSelectedResourcesType]

      /* In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(allResources[sourceParentId.replace("resource_", "")], sourceIndex, destIndex)
        allResources[sourceParentId.replace("resource_", "")] = items
        setAllResourcesChanged(!allResourcesChanged)
      } else {
        var newSourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        var newDestSubItems
        var formData = new FormData()

        newDestSubItems = topicsResources[`topic_` + topic_id]
        formData.append("add_to_topic", topic_id)
        setTopicsResourcesFetched(false)

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem["sequence"] = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem["sequence"] = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/
        if (hashValue !== "real") {
          formData.append("draft_content", "true")
          formData.append(`stage_id`, selectedStage)
        }
        formData.append("sequence", draggedItem.sequence)
        if (sourceParentId.replace("resource_", "") === "poll" || sourceParentId.replace("resource_", "") === "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else if (sourceParentId.replace("resource_", "") === "document") {
          formData.append("resource_type", "turientimage")
        } else {
          formData.append("resource_type", sourceParentId.replace("resource_", ""))
        }
        formData.append("resource_id", draggedItem.id)

        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
          .then((res) => {
            if (res.data) {
              draggedItem.id = res.data.id
              draggedItem.resource_data = res.data.resource_data
              draggedItem.resource_id = res.data.resource_id
              draggedItem.resource_type = res.data.resource_type
              draggedItem.sequence = res.data.sequence
              delete draggedItem["name"]
              triggerChanges("lessonresources", result.destination.droppableId.split("_")[2])
            }
            setTopicsResourcesFetched(true)
          })
          .catch((error) => {
            showErrorMessage(error)
          })
        /*api end*/
        newDestSubItems.splice(destIndex, 0, draggedItem)
        setAllResourcesFetched(false)
        let temp_data = allResources
        temp_data[sourceParentId.replace("resource_", "")] = newSourceSubItems
        setAllResourcesChanged(!allResourcesChanged)
      }
    } else if (result.source.droppableId.indexOf("topic") > -1) {
      const itemSubItemMap = topicsResources

      let source_topic_id = "topic_" + sourceParentId.split("_")[2]
      let dest_topic_id = "topic_" + destParentId.split("_")[2]

      const sourceSubItems = itemSubItemMap[source_topic_id]
      const destSubItems = itemSubItemMap[dest_topic_id]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(topicsResources[source_topic_id], sourceIndex, destIndex)
        let currentResource = items[result.destination.index]
        let currentData = topicsResources[dest_topic_id]
        if (result.destination.index === 0) {
          currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (result.destination.index === currentData.length - 1) {
          currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > result.destination.index) {
            currentResource.sequence = (
              (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (result.source.index < result.destination.index) {
            currentResource.sequence = (
              (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        handleSave("sequence", currentResource.sequence, currentResource, "resource")
        setTopicsResourcesFetched(!topicsResourcesFetched)
        triggerChanges("lessonresources", result.destination.droppableId.split("_")[2])
        topicsResources[source_topic_id] = items
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = destParentId.split("_")[0].toString() === `resource` ? allResources[props.currentSelectedResourcesType] : destSubItems
        if (destParentId.indexOf("resource") > -1) {
          /*to be present in api success start*/
          draggedItem.dragged_id = draggedItem.id
          draggedItem.id = draggedItem.resource_data.id
          draggedItem.thumbnail_url = draggedItem.resource_data.thumbnail_url
          draggedItem.name = draggedItem.resource_data?.display_name ?? draggedItem.resource_data?.name
          delete draggedItem["resource_data"]
          delete draggedItem["resource_id"]
          delete draggedItem["resource_type"]
          /*to be present in api success end*/
          deleteResource(draggedItem.dragged_id, true, {}, null)
        }
        newDestSubItems.splice(destIndex, 0, draggedItem)
        if (destParentId.indexOf("resource") > -1) {
        } else {
          if (result.destination.index === 0 && destSubItems.length === 1) {
            draggedItem.sequence = result.destination.index
          } else if (result.destination.index === 0) {
            if (draggedItem) {
              draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
            }
          } else if (result.destination.index + 1 === destSubItems.length) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
          } else {
            draggedItem.sequence = (
              (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
              2
            ).toFixed(20)
          }

          draggedItem.topic = destParentId.split("_")[2]

          handleSave("sequence", draggedItem.sequence, draggedItem, "resource")
          draggedItem.requested_topic = destParentId.split("_")[2]
          setTopicsResourcesFetched(!topicsResourcesFetched)
          triggerChanges("lessonresources", result.source.droppableId.split("_")[2], result.destination.droppableId.split("_")[2])
        }
      }
    }
  }

  function onDragStart(result) {
    if (result.type === "resourceDraggable") {
    }
  }
  function onDragUpdate(result) {
    if (result.type === "resourceDraggable" && false) {
      let stage_data = modules[selectedStage]
      if (result.destination && result.destination.droppableId === "moduleDraggable") {
        if (stage_data.modules[result.destination.index]) {
          let datum_id = stage_data.modules[result.destination.index].id
          if (selectedModuleToggle[datum_id] !== true) {
            document.getElementById("click_module" + datum_id).click()
          }
        }
      }
    }
  }

  const assignSuggestedTopic = async (id, sequence, module_id) => {
    let formData = new FormData()
    formData.append("topic_sequence", sequence)
    formData.append("module_id", module_id)
    let response = await postRequest(`/unassigned-topics/${id}/move_unassigned_topic_to_session/`, formData)
    if (response.success) {
      if (selectedStageName === "Workshop") {
        let topic_droped_to_module = modules[selectedStage].modules.filter((session) => session.id == module_id)
        if (topic_droped_to_module.length > 0) {
          triggerChanges("todo" + topic_droped_to_module[0].session_type, id, module_id + `_` + topic_droped_to_module[0].session_type)
        }
      }
    }
  }

  function toggleLeftNav(value) {
    if (value !== leftNavOpen) {
      setLeftNavOpen(value)
    }
  }

  function findScrollableOrNot() {
    let ele = document.getElementById("sections")
    if (ele !== null && ele !== undefined) {
      return ele.scrollWidth > ele.clientWidth
    }
  }

  function scrollRight() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft += 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function scrollLeft() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft -= 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function calculateWidth() {
    let ele = document.getElementById("coursename")
    if (ele !== null) {
      let data = 1100 - parseInt(ele.offsetWidth) - 50
      return { width: data, overflowX: "scroll" }
    }
  }
  function handleCloseBreakoutOverlay() {
    setCreateBreakoutSessionOpened(false)
    setSelectedTopicForBreakout("")
    setSelectedBreakoutForEdit("")
    setCreatedBreakoutSession("")
    fetchSelectedTopic(selectedBreakoutForEdit.topic, true)
  }
  function courseDaySuggestionModal() {
    return (
      <Modal show={showSuggestDayModal} centered className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}>
        <CloseBtn onClick={() => setShowSuggestDayModal(false)} />
        <div className={`${StylesB.delete_modal_header} align-items-center pr-4`}>
          <FontAwesomeIcon className={`my-auto mr-2 pointer`} color="#717886" style={{ height: "50px", width: "50px" }} icon={faClock} />
          <div>
            <p className={`pl-2`} style={{ fontSize: "18px", color: "#E0E2E5" }}>
              More than 8 hours of day {recentDay} has been scheduled.
            </p>
          </div>
        </div>
        <Modal.Body
          className="text-center"
          style={{
            backgroundColor: "#121b2b",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <div className={`text-center align-items-center`}>
            <p
              style={{
                color: "#E0E2E5",
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              Do you want to continue with day {recentDay} {recentDay < courseDays ? `or start on day ${recentDay + 1}` : ""} ?{" "}
            </p>
            <div className={`px-5`} style={{ display: "grid" }}>
              <button
                className={`px-4 py-1 mb-2 border_on_hover mx-5`}
                onClick={(e) => {
                  setSuggestedDay(e, recentDay)
                }}
                style={{
                  backgroundColor: "#586886",
                  borderRadius: "9px",
                  border: "1px solid #586886",
                  color: "#E0E2E5",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Continue with day {recentDay}
              </button>
              {recentDay < courseDays ? (
                <button
                  className={`px-4 py-1 border_on_hover mx-5`}
                  onClick={(e) => {
                    setSuggestedDay(e, recentDay + 1)
                  }}
                  style={{
                    backgroundColor: "#586886",
                    borderRadius: "9px",
                    border: "1px solid #586886",
                    color: "#E0E2E5",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Start with day {recentDay + 1}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
  function fetchAllResources(pActiveResType = "") {
    return 1

    let allRes = {},
      currentSelectedResourcesType = activeResourceType
    let data_type = ""
    if (pActiveResType !== "") {
      currentSelectedResourcesType = pActiveResType
    }
    let type = "basic-" + currentSelectedResourcesType + "s-list"
    if (currentSelectedResourcesType === "turientimage") {
      type = "basic-images-list"
      data_type = "&file_type=0"
    } else if (currentSelectedResourcesType === "quiz") {
      type = "basic-quizzes-list"
      data_type = "&data_type=0&published=true"
    } else if (currentSelectedResourcesType === "poll") {
      type = "basic-quizzes-list"
      data_type = "&data_type=1&published=true"
    } else if (currentSelectedResourcesType === "pop-quiz") {
      type = "basic-quizzes-list"
      data_type = "&data_type=2&published=true"
    } else if (currentSelectedResourcesType === "document") {
      type = "basic-images-list"
      data_type = `&file_type=all`
    } else if (currentSelectedResourcesType === "checklist") {
      type = "checklist"
      data_type = "&limit=8&published=true"
    } else if (currentSelectedResourcesType === "casestudy") {
      type = "case-study"
      data_type = "&limit=8&published=true"
    }

    let search = "" //searchValue === '' ? '' : `&search=${searchValue}`
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/?paginate_by=10${search}&adminsite=true${data_type}`, { headers: login_credentials })
      .then((res) => {
        allRes[currentSelectedResourcesType] = res.data.results
        setAllResources(allRes)
        Common.SetValForID(setAllResourcesNext, currentSelectedResourcesType, res.data.next === null ? "" : res.data.next)
        setAllResourcesChanged(!allResourcesChanged)
      })
  }

  function refetchLeftSideResourcesBar(pActiveResType = "") {
    const evt = new CustomEvent("my-refresh-resources", {
      bubbles: true,
      detail: pActiveResType,
    })
    document.dispatchEvent(evt)
  }

  useEffect(() => {
    if (location.id && selectedStageName === "Workshop") {
      if (!breakoutGroupsDataFetched) {
        fetchBreakoutGroupsData()
      }
      if (!breakoutGroupsResourcesFetched) {
        fetchBreakoutGroupsResources()
      }
    }
  }, [location.id, selectedStageName])

  useEffect(() => {
    if (openInstructions && !breakoutGroupInstructionsFetched) {
      fetchBreakoutGroupsInstructions()
    }
  }, [openInstructions])

  async function fetchBreakoutGroupsData() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/breakoutgroups/?training=${location.id}`, { headers: login_credentials })
      .then((res) => {
        setBreakoutGroupList(res.data.results)
        setBreakoutGroupsDataFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  async function createBreakoutGroup(e, last_create = null, value = null) {
    if (e != null) {
      e.preventDefault()
    }
    var formData = new FormData()
    let group_name = ""
    group_name = "Group" + String(breakoutGroupList.length + 1)
    if (location.id) {
      formData.append("training", location.id)
      formData.append("title", group_name)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + `/breakoutgroups/create/`, formData, { headers: login_credentials })
        .then((res) => {
          let group_data = [...breakoutGroupList]
          group_data.push(res.data)
          setBreakoutGroupList(group_data)
          setCreatedGroup(true)
          setRefetchGroups(false)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function deleteBreakoutGroup(id) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/breakoutgroups/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        let arr = breakoutGroupList.filter((item) => item.id !== id)
        setBreakoutGroupList(arr)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  async function updateBreakoutGroup(value, id, index, breakoutGroups) {
    var formData = new FormData()
    formData.append("title", value)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/breakoutgroups/${id}/`, formData, { headers: login_credentials })
      .then((res) => {
        let group_data = [...breakoutGroups]
        group_data[index].title = res.data.title
        setBreakoutGroupList(group_data)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  async function createCommomResourceInstruction() {
    var formData = new FormData()
    if (course_template?.id) {
      formData.append("template", selectedTemplate)
      formData.append("instruction", "")
      if (breakoutGroupInstructions.length > 0) {
        let value = (parseFloat(breakoutGroupInstructions[breakoutGroupInstructions.length - 1].sequence) + 10).toFixed(20)
        formData.append("sequence", value)
      }
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + `/common-group-resource/`, formData, { headers: login_credentials })
        .then((res) => {
          let dummy = [...breakoutGroupInstructions]
          dummy.push(res.data)
          setBreakoutGroupInstructions(dummy)
        })
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }
  async function updateCommomResourceInstruction(value, template, datum, index, type = null, group_id = null, instructions = null) {
    var formData = new FormData()
    formData.append("template", template)
    if (type === "instruction") {
      formData.append("instruction", value)
    }
    if (type === "breakout_groups" || type === "breakout_groups_instruction") {
      let dummy1 = { ...datum }
      let dummy = []
      if (dummy1.breakout_groups) {
        dummy = [...dummy1.breakout_groups]
      } else {
        dummy1["breakout_groups"] = []
        dummy = [...dummy1.breakout_groups]
      }
      let fil_arr = dummy.filter((res) => res.id == group_id)
      if (fil_arr.length === 0) {
        let res = {}
        res["id"] = group_id
        let ind = breakoutGroupList.findIndex((res) => res.id == group_id)
        res["title"] = breakoutGroupList[ind].title
        dummy.push(res)
        datum["breakout_groups"] = dummy
      } else {
        let res_arr = []
        for (let i = 0; i < dummy.length; i++) {
          if (dummy[i].id != group_id) {
            res_arr.push(dummy[i])
          }
        }
        datum["breakout_groups"] = res_arr
      }
    }

    for (var k in datum) {
      if (datum[k] !== null) {
        if (k === "start_date" || k === "end_date") {
          if (datum[k]) {
            formData.append(k, new Date(datum[k]).toISOString())
          }
        } else {
          if (k === "breakout_groups") {
            let dummy1 = { ...datum }
            let dummy = [...dummy1.breakout_groups]
            let res_arr = []
            for (let i = 0; i < dummy.length; i++) {
              res_arr.push(dummy[i].id)
            }
            formData.append("breakout_groups", JSON.stringify(res_arr))
          }
        }
      }
    }
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/common-group-resource/${datum.id}/`, formData, { headers: login_credentials })
      .then((res) => {
        if (type === "instruction") {
          let dummy = { ...instructionsLoader }
          let text = `instruction_` + datum.id
          dummy[text] = false
          setInstructionsLoader(dummy)
        }

        if (type === "instruction" || type === "breakout_groups_instruction") {
          if (instructions) {
            let dummy = [...instructions]
            dummy[index] = res.data
            setBreakoutGroupInstructions(dummy)
          } else {
            let dummy = [...breakoutGroupInstructions]
            dummy[index] = res.data
            setBreakoutGroupInstructions(dummy)
          }
        } else if (type === "breakout_groups") {
          let dummy = [...breakoutGroupResources]
          dummy[index] = res.data
          setBreakoutGroupResources(dummy)
        }
      })
      .catch((error) => {
        console.error("err", error)
      })
  }
  async function fetchBreakoutGroupsResources(template = "", parent_template = "", draft = "") {
    if (template === "") template = selectedTemplate
    if (parent_template === "") parent_template = course_template.id
    if (draft === "") draft = hashValue === "real" ? `false` : `true`

    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/common-group-resource/?type=resource&template=${template}`, { headers: login_credentials })
      .then((res) => {
        setBreakoutGroupResources(res.data)
        setBreakoutGroupsResourcesFetched(true)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  async function fetchBreakoutGroupsInstructions() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/common-group-resource/?type=instruction&template=${selectedTemplate}`, { headers: login_credentials })
      .then((res) => {
        setBreakoutGroupInstructions(res.data)
        setBreakoutGroupInstructionsFetched(true)
        if (res.data.length === 0) {
          createCommomResourceInstruction()
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  async function deleteBreakoutGroupsResources(resource_id, index, type) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/common-group-resource/${resource_id}/?draft_content=${hashValue === "real" ? `false` : `true`}`, {
        headers: login_credentials,
      })
      .then((res) => {
        if (type == "resource") {
          let arr = breakoutGroupResources.filter((item) => item.id !== resource_id)
          setBreakoutGroupResources(arr)
        } else {
          let arr = breakoutGroupInstructions.filter((item) => item.id !== resource_id)
          setBreakoutGroupInstructions(arr)
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  useEffect(() => {
    if (showCreatingGroupsModal === false) {
      setRefetchGroups(true)
    }
  }, [showCreatingGroupsModal])

  function getSelectionStatus(id, arr) {
    let flag = 0
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          flag = 1
        }
      }
    }
    if (flag === 1) {
      return <FontAwesomeIcon icon={faCheck} className={`ml-auto`} />
    }
  }

  function commonItemsData() {
    let topic_id = selectedTemplate,
      module_id = 0,
      data = 0
    return (
      <div className={`d-inline-flex w-100 align-items-center`}>
        <div className={`w-100`} style={{}}>
          <div
            className={`d-inline-flex w-100 align-items-center py-1`}
            style={{
              borderBottom: "1px solid #929BBD80",
              boxShadow: "0px 3px 12px #00000099",
            }}
          >
            <p className={`mb-0 ml-2`} style={{ color: "#E0E2E5", fontSize: "16px", fontWeight: "500" }}>
              Add common items for groups
            </p>
            <img
              onClick={() => {
                setShowCommonItemsModal(false)
                setDisabledResources([])
                setBreakoutGroupInstructionsFetched(false)
                setOpenInstructions(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`ml-auto mr-2 mb-0 pointer`}
              style={{ objectFit: `contain`, height: `18px` }}
            />
          </div>
          <div className={`w-100 px-2 py-3`} style={{ maxHeight: "75vh", overflowY: "scroll" }}>
            <div
              className={`w-100 mb-2`}
              style={{
                backgroundColor: "#303C54",
                borderRadius: "9px",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div
                className={`d-inline-flex w-100 align-items-center px-2 py-2 pointer`}
                onClick={() => {
                  setOpenInstructions(!openInstructions)
                }}
                style={{
                  borderBottom: openInstructions ? "1px solid #FFFFFF40" : "",
                }}
              >
                <FontAwesomeIcon icon={faCommentAlt} className={`mr-2`} color="#E0E2E5" />
                <p className={`mb-0 ml-2`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                  Add general Instructions
                </p>
                <FontAwesomeIcon icon={openInstructions ? faChevronCircleUp : faChevronCircleDown} className={`ml-auto`} color="#E0E2E5" />
              </div>
              {openInstructions ? (
                breakoutGroupInstructionsFetched ? (
                  breakoutGroupInstructions.length > 0 ? (
                    breakoutGroupInstructions.map((instruction, i_indx) => {
                      return (
                        <div
                          className={`w-100 pb-2`}
                          key={`instruction` + instruction.id}
                          style={{
                            borderBottom: i_indx + 1 < breakoutGroupInstructions.length ? "1px solid #FFFFFF60" : "",
                          }}
                        >
                          <InputGroup
                            className="py-0 d-block "
                            style={{
                              borderRadius: "9px",
                              border: "1px solid transparent",
                            }}
                          >
                            <InputGroup.Prepend
                              className="border-0 d-inloine-flex w-100 align-items-center"
                              style={{
                                backgroundColor: "#303C54",
                                borderRadius: "9px 0px 0px 9px",
                              }}
                            >
                              <InputGroup.Text
                                className="border-0 d-flex justify-content-center pb-0"
                                style={{
                                  backgroundColor: "#303C54",
                                  borderRadius: "9px 0px 0px 9px",
                                  color: "#E0E2E5",
                                  opacity: "41%",
                                  fontSize: "16px",
                                }}
                                id="basic-addon1"
                                onClick={() => {
                                  document.getElementById(`instruction` + instruction.id).focus()
                                }}
                              >
                                Type Description
                              </InputGroup.Text>
                              <img
                                src={DeleteIcon}
                                className={`ml-auto pointer pt-2 pr-2`}
                                onClick={(e) => {
                                  deleteBreakoutGroupsResources(instruction.id, i_indx, "instruction")
                                }}
                              />
                            </InputGroup.Prepend>
                            <FormControl
                              className={`border-0 d-flex pt-0 w-100 justify-content-center shadow-none  text-white`}
                              style={{
                                backgroundColor: "#303C54",
                                borderRadius: "0px",
                                fontSize: "16px",
                              }}
                              as="textarea"
                              type="text"
                              name="instruction"
                              defaultValue={instruction.instruction}
                              onChange={(e) => {
                                e.preventDefault()
                                let dummy = { ...instructionsLoader }
                                let text = `instruction_` + instruction.id
                                dummy[text] = true
                                setInstructionsLoader(dummy)
                                delayedQuery(
                                  e.target.name,
                                  e.target.value,
                                  instruction,
                                  "instruction",
                                  selectedTemplate,
                                  i_indx,
                                  null,
                                  breakoutGroupInstructions
                                )
                              }}
                              id={`instruction` + instruction.id}
                              placeholder=""
                            />
                          </InputGroup>
                          <div className={`d-inline-flex w-100 align-items-center px-2 pb-2`} style={{}}>
                            <Dropdown className={`breakout-groups`}>
                              <Dropdown.Toggle
                                size="lg"
                                className={`border-0 px-2 py-1 d-inline-flex align-items-center ${StylesA.btn_focus}`}
                                id="dropdown_list"
                                style={{
                                  fontSize: "14px",
                                  backgroundColor: "#586886",
                                  borderRadius: "4px",
                                }}
                              >
                                {instruction?.breakout_groups?.length > 0 ? (
                                  instruction?.breakout_groups?.map((grp, indx) => {
                                    return (
                                      <p
                                        key={`${instruction.id}_selected_groups_${indx}`}
                                        className={`mb-0 ${indx + 1 == instruction?.breakout_groups.length ? "pr-3" : "pr-2"}`}
                                      >
                                        {grp.title}
                                        {`${indx + 1 < instruction?.breakout_groups?.length ? "," : ``}`}
                                      </p>
                                    )
                                  })
                                ) : (
                                  <p className={`mb-0 pr-1`}>All Groups</p>
                                )}
                                <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className={`br-9px breakout-groups`}>
                                {breakoutGroupList.length > 0 ? (
                                  breakoutGroupList.map((group, index) => {
                                    return (
                                      <Dropdown.Item
                                        className={`${StylesB.dropitem} text-white fs-14px pl-3 py-1 d-inline-flex`}
                                        style={{ minWidth: `max-content` }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          updateCommomResourceInstruction(
                                            "breakout_groups",
                                            selectedTemplate,
                                            instruction,
                                            i_indx,
                                            "breakout_groups_instruction",
                                            group.id
                                          )
                                        }}
                                      >
                                        <p className={`mb-0 w-100 d-inline-flex align-items-center`}>
                                          {group.title}
                                          {getSelectionStatus(group.id, instruction?.breakout_groups)}
                                        </p>
                                      </Dropdown.Item>
                                    )
                                  })
                                ) : (
                                  <p
                                    className={`text-center mb-0 mx-auto py-2`}
                                    style={{
                                      color: "#E0E2E5",
                                      fontSize: "14px",
                                    }}
                                  >
                                    No groups created yet
                                  </p>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            {props?.isPermitted && (
                              <p
                                className="mb-0 ml-auto"
                                style={{
                                  fontSize: "14px",
                                  color: "#E0E2E5",
                                  bottom: "10px",
                                  right: "10px",
                                }}
                              >
                                <span className="mb-0 ml-2" style={{ fontSize: "14px" }}>
                                  {instructionsLoader[`instruction_` + instruction.id] ? (
                                    <span className={`bg-transparent border-0`} id={`basic-addon`}>
                                      <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                                    </span>
                                  ) : (
                                    <img
                                      src={CheckIcon}
                                      alt={`changes_saved_check`}
                                      className={`ml-auto mb-0 mr-1`}
                                      style={{
                                        objectFit: `contain`,
                                        height: `11px`,
                                      }}
                                    />
                                  )}
                                  All changes are saved
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className={`w-100`}>
                      <p className={`w-100 py-3 mb-0 text-center`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                        No instructions created.
                      </p>
                    </div>
                  )
                ) : (
                  <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                    <Spinner animation="border" variant="secondary" size="sm" />
                  </Col>
                )
              ) : null}
            </div>
            {openInstructions ? (
              <p
                className={`mb-3 pointer`}
                onClick={() => {
                  createCommomResourceInstruction()
                }}
                style={{
                  color: "#D3D5E2",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <u>{breakoutGroupInstructions.length > 0 ? `Add more Instructions` : `Add Instructions`}</u>
              </p>
            ) : null}

            <div
              key={"div2"}
              className={`px-0`}
              style={{
                backgroundColor: "#303C54",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div
                style={{
                  borderRadius: "8px 8px 0px 0px",
                  borderBottom: `1px dashed #838b9e`,
                  padding: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    color: "#E0E2E5",
                    lineHeight: 1,
                    opacity: 0.6,
                  }}
                >
                  Common Resources
                </p>
              </div>
              {showCommonResources()}
              <div
                className="d-flex p-1"
                style={{
                  borderTop: `1px dashed #838b9e`,
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <OverlayTrigger overlay={<Tooltip>Add video</Tooltip>}>
                  {/*<img onClick={(e)=>{openNavWithResourceSection('Resource','video');uploadDocs(e,topic_id,module_id,data,false); showSection('video')}} src={multimedia} alt={`add Video button`} title={`Add Videos`} className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`} style={{ objectFit: `contain`, width: `21px`, height: `21px` }}/>*/}
                  <FontAwesomeIcon
                    icon={faVideo}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "video")
                      uploadDocs(e, topic_id, module_id, breakoutGroupResources, false, "video")
                    }}
                    title={`Add Videos`}
                    alt={`Add Videos`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add blog</Tooltip>}>
                  <img
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "blog")
                      uploadDataProps(topic_id, module_id, breakoutGroupResources, "")
                      setAddBlog(true)
                    }}
                    src={WhiteBlogIcon}
                    title={`Add Blog`}
                    alt={`add blog button`}
                    className={`${
                      leftNavOpen && activeResourceType === "blog" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                    } my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add documents</Tooltip>}>
                  <img
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "document")
                      uploadDocs(e, topic_id, module_id, breakoutGroupResources, false, "document")
                    }}
                    src={documents}
                    alt={`Add Documents`}
                    title={`Add Documents(Doc, Excel, PPT, PDF, Google Docs Link)`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add images</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faImage}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "turientimage")
                      uploadDocs(e, topic_id, module_id, breakoutGroupResources, true, "turientimage")
                    }}
                    title={`Add Images`}
                    alt={`Add Images`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add links</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faLink}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "link")
                      uploadDocs(e, topic_id, module_id, breakoutGroupResources, false, "link")
                    }}
                    title={`Add Links`}
                    alt={`Add Links`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function showCommonResources() {
    let next_url = null
    let see_more_loader = false
    let topic_id = selectedTemplate,
      module_id = 0,
      data = breakoutGroupResources

    if (breakoutGroupResources) {
      return (
        <Droppable droppableId={`${selectedTemplate}_groupresource`} type={`resourceDraggable`}>
          {(provided, snapshot) => (
            <div
              id={`drop-area`}
              onDragEnd={onDragEnd}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={(e) => onDrop(e, module_id, topic_id, data)}
              className={`bg-transparent px-4 ${Styles.opacity_1}`}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {breakoutGroupResources.map((common_resource, index) => {
                let item = common_resource.resource
                let { typeId, imgObj } = getImageToBeRendered(item)
                return (
                  <>
                    <Draggable
                      key={item.id + `_groupresource_name_` + index}
                      draggableId={item.id.toString() + `-groupresource` + "_" + index.toString()}
                      index={index}
                      className={item.resource_data !== null ? "" : "d-none1"}
                      isDragDisabled={isPermitted}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={item.resource_data !== null ? "resource-item" : "d-none1"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            className={`pr-2 d-inline-flex w-100`}
                            style={{
                              color: `#E0E2E5`,
                              background: "#4F6080",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="d-flex" style={{ alignItems: "center" }}>
                              <FontAwesomeIcon
                                className={`${modulesResourcesFetched} ${topicsResourcesFetched} ml-1 ${item.resource_type ? `d-none` : ``}`}
                                icon={faSpinner}
                                size="lg"
                              />
                              {item.resource_data ? (
                                item.resource_data.thumbnail_url ? (
                                  <LazyLoadImage
                                    className={`my-auto`}
                                    src={item.resource_data.thumbnail_url}
                                    alt={item.name}
                                    style={{
                                      width: `28px`,
                                      height: `20px`,
                                      fontSize: `12px`,
                                    }}
                                  />
                                ) : (
                                  <>
                                    {typeId === 1 ? (
                                      <FontAwesomeIcon
                                        icon={imgObj}
                                        className={`my-0`}
                                        style={{
                                          color: "#67edfd",
                                          marginBottom: 0,
                                          width: `28px`,
                                          height: `20px`,
                                        }}
                                      />
                                    ) : (
                                      <LazyLoadImage
                                        className={`my-auto`}
                                        src={imgObj}
                                        alt={item.name}
                                        style={{
                                          width: `28px`,
                                          height: `20px`,
                                          fontSize: `12px`,
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              ) : (
                                ``
                              )}
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: "0px 0px 0px 10px",
                                }}
                              >
                                {item.resource_data ? item.resource_data?.display_name ?? item.resource_data?.name : item.name}
                              </p>
                            </div>
                            <div className={`d-inline-flex py-1 align-items-center`}>
                              <Dropdown className={`breakout-groups ml-auto mr-2`}>
                                <Dropdown.Toggle
                                  size="lg"
                                  className={`border-0 px-2 py-1 d-inline-flex align-items-center ${StylesA.btn_focus}`}
                                  id="dropdown_list"
                                  style={{
                                    fontSize: "14px",
                                    backgroundColor: "#303C54",
                                    borderRadius: "4px",
                                  }}
                                >
                                  {common_resource?.breakout_groups?.length > 0 ? (
                                    common_resource?.breakout_groups?.map((grp, indx) => {
                                      return (
                                        <p
                                          key={`${common_resource.id}_selected_groups_${indx}`}
                                          className={`mb-0 ${indx + 1 == common_resource?.breakout_groups.length ? "pr-3" : "pr-2"}`}
                                        >
                                          {grp.title}
                                          {`${indx + 1 < common_resource?.breakout_groups?.length ? "," : ``}`}
                                        </p>
                                      )
                                    })
                                  ) : (
                                    <p className={`mb-0 pr-1`}>All Groups</p>
                                  )}
                                  <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`br-9px breakout-groups`}>
                                  {breakoutGroupList.length > 0 ? (
                                    breakoutGroupList.map((group, b_index) => {
                                      return (
                                        <Dropdown.Item
                                          className={`${StylesB.dropitem} text-white fs-14px pl-3 py-1 d-inline-flex`}
                                          style={{ minWidth: `max-content` }}
                                          onClick={(e) => {
                                            e.preventDefault()
                                            updateCommomResourceInstruction(
                                              "breakout_groups",
                                              selectedTemplate,
                                              common_resource,
                                              index,
                                              "breakout_groups",
                                              group.id
                                            )
                                          }}
                                        >
                                          <p className={`mb-0 w-100 d-inline-flex align-items-center`}>
                                            {group.title}
                                            {getSelectionStatus(group.id, common_resource?.breakout_groups)}
                                          </p>
                                        </Dropdown.Item>
                                      )
                                    })
                                  ) : (
                                    <p
                                      className={`text-center mb-0 mx-auto py-2`}
                                      style={{
                                        color: "#E0E2E5",
                                        fontSize: "14px",
                                      }}
                                    >
                                      No groups created yet
                                    </p>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                              <img
                                src={DeleteIcon}
                                onClick={(e) => {
                                  deleteBreakoutGroupsResources(common_resource.id, index, "resource")
                                }}
                                alt={"resource delete icon"}
                                className={`my-auto pointer`}
                                style={{ objectFit: `contain`, height: `13px` }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </>
                )
              })}
              {provided.placeholder}
              <React.Fragment>
                <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>
                  <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent  text-center ${files.length === 0 ? `` : `border-0`}`}>
                    <div className={`w-100 col-12`}>
                      <Form className="my-form mb-0">
                        {!isPermitted && (
                          <div>
                            <p className={`${StylesM.font_14} mb-2`}>Drag and drop media/document files to add content</p>
                          </div>
                        )}
                        {files.length === 0 ? (
                          <input
                            className={`d-none`}
                            type="file"
                            id={"fileElem_" + topic_id}
                            multiple
                            accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                            onClick={(e) => {
                              clickedFileInput(e)
                            }}
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              fileInputClicked(e, topic_id, module_id, data)
                              validateAndAdd(e)
                            }}
                          />
                        ) : null}
                      </Form>
                    </div>
                    {uploadInModule !== null && uploadInTopic !== null && uploadDataSequence !== null && uploadInTopic === topic_id ? (
                      <UploadFiles
                        files={files}
                        setFiles={(value) => setFiles(value)}
                        fetchTopicResources={(id) => fetchBreakoutGroupsResources()}
                        showSection={showSection}
                        setShowSection={setShowSection}
                        refetchLeftSideResourcesBar={refetchLeftSideResourcesBar}
                        properties={{
                          module: "Common Items Group",
                          template: uploadInTopic,
                          mainId: uploadInTopic,
                          itemName: " ",
                          add_to_group_resource: "true",
                          sequence: uploadDataSequence,
                          draft_content: hashValue === "real" ? `false` : `true`,
                          stage_id: selectedStage,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function creatingGroupsData() {
    return (
      <div className={`d-inline-flex w-100 align-items-center`}>
        <div className={`w-100`}>
          <div className={`d-inline-flex w-100 align-items-center py-1`} style={{ borderBottom: "1px solid #929BBD80" }}>
            <p className={`mb-0 ml-2`} style={{ color: "#E0E2E5", fontSize: "18px" }}>
              Create Groups
            </p>
            <img
              onClick={() => {
                setShowCreatingGroupsModal(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`ml-auto mr-2 mb-0 pointer`}
              style={{ objectFit: `contain`, height: `18px` }}
            />
          </div>
          <div className={`w-100 px-2 py-3`}>
            {breakoutGroupList.length > 0 ? (
              breakoutGroupList.map((group, index) => {
                return (
                  <div
                    className={`mb-1 d-inline-flex align-items-center w-100`}
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "9px",
                      boxShadow: "0px 3px 6px #00000029",
                    }}
                    key={`group_${group.id}`}
                  >
                    <input
                      type="text"
                      name="title"
                      id={group.id + `-group-input-id`}
                      defaultValue={group.title}
                      placeholder={"Enter group Title"}
                      onChange={(e) => {
                        e.preventDefault()
                        delayedQuery(e.target.name, e.target.value, group, "group_updation", selectedTemplate, index, null, breakoutGroupList)
                      }}
                      className={`${StylesQ.question_title_input} ${StylesQ.question_title_input_size} ${!isPermitted && "disable-content"} form-control px-3`}
                      required
                    />
                    {isMuralEnabled && (
                      <ActivityRoomWokSpace
                        mural_workspace={course_template?.activity_workspace}
                        activity_workspace={group?.id ? group : {}}
                        type={"group"}
                        groupIndex={index}
                        breakoutGroups={breakoutGroupList}
                        setBreakoutGroupList={setBreakoutGroupList}
                        setCanvasLink={true}
                        mural_account={course_template?.mural_account}
                        isPermitted={!isPermitted}
                      />
                    )}
                    {!isPermitted && (
                      <img
                        src={DeleteIcon}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          deleteBreakoutGroup(group.id)
                        }}
                        alt={`group delete icon`}
                        className={`mr-2 ml-1 mb-0 pointer ${!isMuralEnabled && "ml-auto"}`}
                        style={{ objectFit: `contain`, height: `18px` }}
                      />
                    )}
                  </div>
                )
              })
            ) : (
              <p className={`text-center mx-auto py-3`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                No groups created yet
              </p>
            )}
            {!isPermitted && (
              <div className={`d-inline-flex w-100 align-items-center pt-2`}>
                <Button
                  className={`${StylesB.add_button} border_on_hover mr-2 d-flex `}
                  variant="none"
                  onClick={() => {
                    setShowCreatingGroupsModal(false)
                  }}
                >
                  I am done
                </Button>
                <Button
                  className={`${StylesB.add_button} border_on_hover d-flex `}
                  variant="none"
                  onClick={(e) => {
                    createBreakoutGroup(e)
                  }}
                >
                  Add Group
                </Button>
                <Button
                  className={`${StylesB.add_button} border_on_hover ml-2 d-flex `}
                  variant="none"
                  onClick={(e) => {
                    setShowCommonItemsModal(true)
                    setDisabledResources(disabledResourceType)
                  }}
                >
                  {breakoutGroupsResourcesFetched && breakoutGroupResources.length > 0 ? `Edit common items` : `Add common items`}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <SEO title="Training Content" />
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} onDragUpdate={onDragUpdate} isDragDisabled={!isPermitted}>
        <DeleteModal
          show={dmodal}
          onHide={handleClose}
          itemName={
            selectedDeletingModule && selectedDeletingModule.name !== undefined
              ? selectedStageName === "Workshop" && course.course_type !== "1"
                ? "session "
                : "module " + selectedDeletingModule.name
              : ""
          }
          action={deleteModule}
        />
        <Modal
          show={showInstructionCreatingGroupsModal}
          onHide={() => {
            setShowInstructionCreatingGroupsModal(false)
          }}
          centered
          className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
        >
          <Modal.Body className="p-0" style={{ backgroundColor: "#212C42", borderRadius: "9px" }}>
            <div className={`d-inline-flex w-100 align-items-center px-3 py-3`}>
              <div className={`w-75`}>
                <p
                  className={`mb-2`}
                  style={{
                    color: "#E0E2E5",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  You don’t have any groups created
                </p>
                <p style={{ color: "#E0E2E5", fontSize: "12px" }}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
                  diam voluptua.{" "}
                </p>
                <div className={`d-inline-flex w-100 align-items-center`}>
                  <Button
                    disabled={!createdGroup}
                    className={`${StylesB.add_button} border_on_hover d-flex `}
                    variant="none"
                    onClick={(e) => {
                      setShowInstructionCreatingGroupsModal(false)
                      setShowCreatingGroupsModal(true)
                    }}
                  >
                    {createdGroup ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                    Create Groups Now
                  </Button>
                  <Button
                    className={`${StylesB.add_button} border_on_hover d-flex ml-2`}
                    variant="none"
                    onClick={() => {
                      setShowInstructionCreatingGroupsModal(false)
                    }}
                  >
                    Do it Later
                  </Button>
                </div>
              </div>
              <div className={`w-25`}>
                <img src={BreakoutGroupsCreation} alt={`groups creation icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain` }} />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {activeSuggestedTopicData ? (
          <Modal
            show={showDescriptionmodal}
            centered
            onHide={() => {
              setShowDescriptionmodal(false)
              setActiveSuggestedTopicData(null)
            }}
            size="lg"
            className={``}
            style={{ backgroundColor: "transparent" }}
          >
            <Modal.Body
              className="text-left p-0"
              style={{
                backgroundColor: "#212C42",
                border: "1px solid #707070",
                borderRadius: "5px",
              }}
            >
              <div
                className={`box text-dark p-3 w-100 window_screen`}
                onScroll={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <div className={`w-100  d-inline-flex align-items-center`} style={{}}>
                  <p
                    className={`mb-0 pointer pl-2`}
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#E0E2E5",
                    }}
                  >
                    {activeSuggestedTopicData?.topic?.name}
                  </p>
                  <img
                    src={CancelIcon}
                    className="mb-0 pointer ml-auto mr-2"
                    style={{ fontSize: "16px", width: "16px" }}
                    onClick={() => {
                      setShowDescriptionmodal(false)
                      setActiveSuggestedTopicData(null)
                    }}
                  />
                </div>
                <div
                  className={`w-100 p-3 mt-3 align-items-center pr-2`}
                  style={{
                    backgroundColor: "#42506C",
                    borderRadius: "9px",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  <div className={`d-flex align-items-center mb-3`} style={{ color: "#E0E2E5" }}>
                    <div className={`pointer pl-2 mr-2`} style={{ fontSize: "14px" }}>
                      Suggested by :{" "}
                    </div>
                    <FontAwesomeIcon className={`mr-1`} style={{ height: "30px", width: "30px" }} icon={faUserCircle} />
                    <div style={{ fontSize: "12px" }}>{activeSuggestedTopicData?.created_by?.first_name}</div>
                  </div>
                  <p className={`mb-0 pointer pl-2 pb-2`} style={{ fontSize: "16px", color: "#E0E2E5" }}>
                    {activeSuggestedTopicData?.topic?.description ? activeSuggestedTopicData?.topic?.description : `No Desctreption added by student`}
                  </p>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : null}
        <Modal
          show={showAddDescriptionmodal}
          size="lg"
          onHide={() => {
            setShowAddDescriptionModal(false)
          }}
          centered
          className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
        >
          <Modal.Body
            className="text-center py-0"
            style={{
              backgroundColor: "#212C42",
              borderRadius: "8px",
              border: "1px solid #707070",
              color: "#E0E2E5",
            }}
          >
            <Col className={`w-100 align-items-center d-inline-flex`}>
              <div className={`w-100 pt-3 pb-2`}>
                <DescriptionInputField
                  datum={activeDatum}
                  handleSave={handleSave}
                  moduleEdited={moduleEdited}
                  course={course}
                  selectedStageName={selectedStageName}
                  showModalHandler={(arg) => {
                    setShowAddDescriptionModal(arg)
                  }}
                  isPermitted={!isPermitted}
                />
              </div>
            </Col>
          </Modal.Body>
        </Modal>
        {courseDaySuggestionModal()}
        <Sidebar
          activeResourceType={activeResourceType}
          setActiveResourceType={(value) => setActiveResourceType(value)}
          activeNavItem={activeNavItem}
          setActiveNavItem={(value) => setActiveNavItem(value)}
          leftNavOpen={leftNavOpen}
          toggleLeftNav={toggleLeftNav}
          enableDND={true}
          allResourcesChanged={allResourcesChanged}
          allResources={allResources}
          allResourcesNext={allResourcesNext}
          setAllResource={(value) => setAllResources(value)}
          currentSelectedResourcesType={currentSelectedResourcesType}
          setHomepageActiveResource={(value) => setCurrentSelectedResourcesType(value)}
          setHomepageAllResource={(value) => setAllResources(value)}
          currentNavTab={currentNavTab}
          setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
          showPollModal={showPollModal}
          showPopQuizModal={showPopQuizModal}
          disabledResources={disabledResources}
        />
        <LayoutHeader />
        {course?.id ? (
          <>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              onClick={(e) => setLeftNavOpen(false)}
              className="px-0 d-flex"
              style={{
                width: "calc(100vw - 60px)",
                justifyContent: leftNavOpen ? "flex-end" : "center",
                minHeight: "calc(100vh - 60px)",
              }}
            >
              <div
                className={`${leftNavOpen ? "" : "col-10"} mt-2 d-flex px-2`}
                style={{
                  alignItems: leftNavOpen ? "flex-end" : "center",
                  flexDirection: "column",
                  width: leftNavOpen ? "calc(100vw - 380px)" : "calc(100vw - 60px)",
                  height: "fit-content",
                }}
              >
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  onMouseOver={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    let ele = document.getElementById("sections")
                    if (ele !== null && ele !== undefined) {
                      if (ele.scrollWidth > ele.clientWidth) {
                        setHovered(true)
                      }
                    }
                  }}
                  className={`${StylesB.course_creation_header} d-flex px-0`}
                >
                  <p
                    className="mb-0 py-2 px-3 font-weight-bold text-color-1"
                    id="coursename"
                    style={{
                      borderRadius: "9px 0px 0px 9px",
                      lineHeight: "1",
                      minWidth: "max-content",
                    }}
                    title={course.name}
                  >
                    {courseName}
                  </p>
                  {findScrollableOrNot() && hovered ? (
                    <FontAwesomeIcon
                      onClick={() => {
                        scrollLeft()
                      }}
                      className="pointer"
                      icon={faChevronLeft}
                      size="lg"
                      style={{
                        objectFit: `contain`,
                        height: `20px`,
                        color: `#E0E2E551`,
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={() => {
                        scrollLeft()
                      }}
                      className="pointer"
                      icon={faChevronLeft}
                      size="lg"
                      style={{
                        objectFit: `contain`,
                        height: `20px`,
                        color: `#E0E2E551`,
                        visibility: "hidden",
                      }}
                    />
                  )}
                  <div id="sections" className="d-flex align-items-center hide_scrollbar" style={calculateWidth()}>
                    <div className="d-flex align-items-center">
                      <p
                        className={`${StylesB.course_nav_item_active} mb-0 pointer py-2 pl-14px pr-14px fs-16px font-weight-bold`}
                        style={{ color: "#E0E2E5", minWidth: "max-content" }}
                        id="course_content"
                      >
                        Class Content
                      </p>
                    </div>
                  </div>
                  {findScrollableOrNot() && hovered ? (
                    <FontAwesomeIcon
                      onClick={() => {
                        scrollRight()
                      }}
                      className="pointer"
                      icon={faChevronRight}
                      size="lg"
                      style={{
                        objectFit: `contain`,
                        height: `20px`,
                        color: `#E0E2E551`,
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={() => {
                        scrollRight()
                      }}
                      className="pointer"
                      icon={faChevronRight}
                      size="lg"
                      style={{
                        objectFit: `contain`,
                        height: `20px`,
                        color: `#E0E2E551`,
                        visibility: "hidden",
                      }}
                    />
                  )}
                  <Image
                    src={CancelIcon}
                    alt={`close`}
                    width={20}
                    height={20}
                    onClick={() => {
                      navigate(`/main/class-dashboard/${selectedTemplate}/${course.id}/`)
                    }}
                    className={`pointer mr-3 ml-auto float-right align-self-center`}
                  />
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  onMouseOver={() => {
                    setHovered(false)
                  }}
                  className="px-0"
                >
                  {showMainSection()}
                </Col>
              </div>
            </Col>
            {selectedTemplate && createBreakoutSessionOpened ? (
              <div
                style={{
                  position: "fixed",
                  top: "0px",
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  className="d-flex"
                  style={{
                    justifyContent: leftNavOpen ? "" : "center",
                    marginLeft: leftNavOpen ? "300px" : "",
                    maxHeight: "90vh",
                    overflowY: "auto",
                  }}
                >
                  <Col xs="12" sm="12" md="8" lg="9">
                    <TestBreakoutSession
                      parent_page={"training_content"}
                      setLeftNavOpen={(value) => setLeftNavOpen(value)}
                      setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
                      leftNavOpen={leftNavOpen}
                      allResourcesChanged={allResourcesChanged}
                      allResources={allResources}
                      setHomepageAllResource={(value) => setAllResources(value)}
                      openNavWithResourceSection={openNavWithResourceSection}
                      currentSelectedResourcesType={currentSelectedResourcesType}
                      resetDragState={() => {
                        setDraggedItem("")
                        setDragEnded(false)
                      }}
                      dragEnded={dragEnded}
                      draggedItem={draggedItem}
                      template_id={selectedTemplate}
                      course_real_template_id={course_template?.id}
                      training_id={location.id}
                      activeSection={activeSection}
                      setActiveSection={(item) => setActiveSection(item)}
                      handleCloseBreakoutOverlay={handleCloseBreakoutOverlay}
                      openCreatingGroupsModal={() => {
                        if (breakoutGroupList.length === 0) {
                          setShowInstructionCreatingGroupsModal(true)
                        }
                      }}
                      refetchBreakoutGroups={refetchGroups}
                      topic_id={selectedTopicForBreakout}
                      activeBreakoutSession={selectedBreakoutForEdit}
                      setCreatedBreakoutSession={(item) => setCreatedBreakoutSession(item)} /*showAllBreakoutSessions={showAllBreakoutSessions}*/
                      draft_content={hashValue === "real" ? `false` : `true`}
                      selectedStage={selectedStage}
                      setShowPollModal={setShowPollModal}
                      setShowPopQuizModal={setShowPopQuizModal}
                      setDisabledResources={setDisabledResources}
                      isPermitted={!isPermitted}
                    />
                  </Col>
                </Col>
              </div>
            ) : (
              ""
            )}
            {showCreatingGroupsModal ? (
              <div
                style={{
                  position: "fixed",
                  top: "0px",
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#00000090",
                }}
              >
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  className="d-inline-flex w-100 pr-5"
                  style={{
                    justifyContent: leftNavOpen ? "flex-end" : "center",
                    maxHeight: "90vh",
                  }}
                >
                  <Col xs="12" sm="12" md="8" lg="9" style={{ maxWidth: "60%" }}>
                    <div
                      className="p-0"
                      style={{
                        backgroundColor: "#212C42",
                        borderRadius: "9px",
                        border: "1px solid #929BBD",
                      }}
                    >
                      {creatingGroupsData()}
                    </div>
                  </Col>
                </Col>
              </div>
            ) : (
              ""
            )}
            {showCommonItemsModal ? (
              <div
                style={{
                  position: "fixed",
                  top: "0px",
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  className="d-flex"
                  style={{
                    justifyContent: leftNavOpen ? "" : "center",
                    marginLeft: leftNavOpen ? "300px" : "",
                    maxHeight: "90vh",
                    overflowY: "auto",
                  }}
                >
                  <Col xs="12" sm="12" md="8" lg="9">
                    <div
                      className="p-0"
                      style={{
                        backgroundColor: "#212C42",
                        borderRadius: "9px",
                        border: "1px solid #929BBD",
                      }}
                    >
                      {commonItemsData()}
                    </div>
                  </Col>
                </Col>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            onClick={(e) => setLeftNavOpen(false)}
            className="px-0 d-flex"
            style={{
              width: "calc(100vw - 60px)",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <NoPermissionError />
          </Col>
        )}
      </DragDropContext>
      <Blog
        showBlog={addBlog}
        setShowBlog={setAddBlog}
        topicId={uploadInTopic}
        sequenceId={uploadDataSequence}
        fetchTopicResources={(id) => (addResourceType === "common" ? fetchBreakoutGroupsResources() : fetchTopicResources(id))}
        refetchLeftSideResourcesBar={() => refetchLeftSideResourcesBar("blog")}
        properties={attrForResources}
      />
    </div>
  )
}

export default CourseContent
